import React, { useState } from 'react';
import { Box, Button, Image, Text, VStack, Link } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import logo from '../../assets/logo.png';
import Login from './Login/Login';

const Home = () => {
  const [showLogin, setShowLogin] = useState(false);

  const handleLoginClick = () => {
    setShowLogin(true);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      backgroundImage="url('/assets/wallpaper.jpg')"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <AnimatePresence>
        {!showLogin && (
          <Box
            as={motion.div}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            background="rgba(255, 255, 255, 0.8)"
            backdropFilter="blur(10px)"
            borderRadius="20px"
            boxShadow="0 4px 30px rgba(0, 0, 0, 0.1)"
            padding="40px"
            textAlign="center"
            width="90%"
            maxWidth="500px"
          >
            <Image src={logo} alt="Logo" width="120px" marginBottom="20px" />
            <VStack spacing={4}>
              <Text fontSize="2xl">Bienvenue sur notre application</Text>
              <Text>Veuillez vous connecter pour accéder à notre service.</Text>
              <Button colorScheme="red" onClick={handleLoginClick}>
                Se connecter
              </Button>
            </VStack>
          </Box>
        )}
      </AnimatePresence>
      <AnimatePresence>{showLogin && <Login />}</AnimatePresence>
      <Box position="absolute" bottom="20px" textAlign="center" width="100%">
        <Text fontSize="sm" color="grey">
          Site créé par{' '}
          <Link href="https://www.linkedin.com/in/siegfried-sekkai/" color="blue.200" isExternal>
            Siegfried Sekkai
          </Link>{' '}
          pour le{' '}
          <Link href="https://www.csor.fr" color="blue.200" isExternal>
            CSOR
          </Link>{' '}
          © 2024.{' '}
          <Link href="/mentions-legales" color="blue.200">
            Mentions légales
          </Link>
        </Text>
      </Box>
    </Box>
  );
};

export default Home;