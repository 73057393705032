import React, { useState, useEffect } from 'react';
import axiosInstance from '../../common/utils/axiosConfig';
import { Box, Button, Select, Heading, VStack } from '@chakra-ui/react';
import formatDate from '../../common/formatdate';

const Appel = () => {
  const [maraudes, setMaraudes] = useState([]);
  const [selectedMaraude, setSelectedMaraude] = useState(null);

  useEffect(() => {
    async function fetchMaraudes() {
      try {
        const response = await axiosInstance.get('/api/maraude/maraude/open');
        if (response.data && Array.isArray(response.data.Maraude)) {
          setMaraudes(response.data.Maraude);
        } else {
          console.error('Les données reçues ne sont pas un tableau de maraudes:', response.data);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des maraudes non finies:', error);
      }
    }
    fetchMaraudes();
  }, []);

  const sendDiscordRequest = async () => {
    if (selectedMaraude) {
      try {
        const discordIds = selectedMaraude.equipe
          .map(membre => membre.fichesAdherent && membre.fichesAdherent[0] ? membre.fichesAdherent[0].discordid : null)
          .filter(discordid => discordid !== null);

        const response = await axiosInstance.post('/api/discord/appel', {
          itineraire: selectedMaraude.itineraire,
          date: formatDate(selectedMaraude.date),
          discordid: discordIds,
          nbPersonnesAttendues: selectedMaraude.nbPersonnesAttendues,
          maraudeId: selectedMaraude._id
        }, {
          headers: { 'Content-Type': 'application/json' }
        });

        if (response.status === 200) {
          console.log('Requête envoyée avec succès');
        } else {
          console.error('Erreur lors de l\'envoi de la requête:', response.statusText);
        }
      } catch (error) {
        console.error('Erreur lors de l\'envoi de la requête:', error);
      }
    }
  };

  const handleMaraudeChange = (event) => {
    const selectedMaraude = maraudes.find(maraude => maraude._id === event.target.value);
    setSelectedMaraude(selectedMaraude);
  };

  return (
    <Box p={4}>
      <Heading as="h6" size="md" mb={4}>
        Lancer un appel
      </Heading>
      <VStack spacing={4}>
        <Select
          placeholder="Sélectionnez une maraude"
          value={selectedMaraude ? selectedMaraude._id : ''}
          onChange={handleMaraudeChange}
        >
          {maraudes.map(maraude => (
            !maraude.finie && (
              <option key={maraude._id} value={maraude._id}>
                {maraude.itineraire} - {formatDate(maraude.date)} - {maraude.nbInscrits}/{maraude.nbPersonnesAttendues} inscrits
              </option>
            )
          ))}
        </Select>
        <Button colorScheme="blue" onClick={sendDiscordRequest}>
          Lancer un appel
        </Button>
      </VStack>
    </Box>
  );
}

export default Appel;
