// MonEspace.js
import React, { useEffect, useState } from 'react';
import axiosInstance from '../common/utils/axiosConfig';
import {
  Box,
  Container,
  Grid,
  Heading,
  Text,
  Input,
  Button,
  VStack,
  HStack,
  Avatar,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react';
import Layout from '../common/Layout';

const MonEspace = () => {
  const [user, setUser] = useState(null);
  const [editable, setEditable] = useState(false);
  const [formData, setFormData] = useState({});
  const [message, setMessage] = useState('');

  const bg = useColorModeValue('gray.100', 'gray.800');
  const color = useColorModeValue('gray.800', 'whiteAlpha.900');

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setMessage('Utilisateur non authentifié');
        return;
      }

      try {
        const response = await axiosInstance.get('/api/users/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = response.data;
        setUser(userData);
        setFormData({
          email: userData.email,
          name: userData.fichesAdherent[0]?.name || '',
          surname: userData.fichesAdherent[0]?.surname || '',
          phone: userData.fichesAdherent[0]?.phone || '',
          city: userData.fichesAdherent[0]?.city || '',
          job: userData.fichesAdherent[0]?.job || '',
        });
      } catch (error) {
        console.error('Erreur lors de la récupération des données utilisateur :', error);
        setMessage('Erreur lors de la récupération des données utilisateur.');
      }
    };

    fetchData();
  }, []);

  const handleEditToggle = () => {
    setEditable(!editable);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.put('/api/users/me', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser(response.data);
      setEditable(false);
    } catch (error) {
      console.error('Erreur lors de la mise à jour des données utilisateur:', error);
    }
  };

  if (!user) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Layout>
      <Container maxWidth="md" overflowY="auto" height="100vh" py={4}>
        <Box bg={bg} p={6} borderRadius="md" boxShadow="md" mb={6}>
          <Heading as="h4" size="lg" mb={4}>
            Mon Espace
          </Heading>

          <Box as="form" onSubmit={handleSubmit}>
            <VStack spacing={4}>
              <Input
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                isDisabled={!editable}
              />
              <Input
                placeholder="Nom"
                name="name"
                value={formData.name}
                onChange={handleChange}
                isDisabled={!editable}
              />
              <Input
                placeholder="Prénom"
                name="surname"
                value={formData.surname}
                onChange={handleChange}
                isDisabled={!editable}
              />
              <Input
                placeholder="Téléphone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                isDisabled={!editable}
              />
              <Input
                placeholder="Ville"
                name="city"
                value={formData.city}
                onChange={handleChange}
                isDisabled={!editable}
              />
              <Input
                placeholder="Profession"
                name="job"
                value={formData.job}
                onChange={handleChange}
                isDisabled={!editable}
              />
              {editable ? (
                <Button type="submit" colorScheme="blue" width="full">
                  Enregistrer
                </Button>
              ) : (
                <Button colorScheme="blue" variant="outline" width="full" onClick={handleEditToggle}>
                  Modifier
                </Button>
              )}
            </VStack>
          </Box>
        </Box>

        <Box bg={bg} p={6} borderRadius="md" boxShadow="md" mb={6}>
          <Heading as="h4" size="lg" mb={4}>
            Mes dernières maraudes
          </Heading>
          <VStack align="start" spacing={4}>
            {user.fichesAdherent[0]?.maraudesFaites.slice(0, 3).map((maraude) => (
              <Box key={maraude._id} p={4} bg={bg} borderRadius="md" width="full">
                <Text fontWeight="bold">{maraude.name}</Text>
                <Text>Date: {new Date(maraude.date).toLocaleDateString()}</Text>
              </Box>
            ))}
          </VStack>
        </Box>

        <Box bg={bg} p={6} borderRadius="md" boxShadow="md" mb={6}>
          <Heading as="h4" size="lg" mb={4}>
            Mes prochaines maraudes
          </Heading>
          <VStack align="start" spacing={4}>
            {/* Exemple statique, vous devez remplacer par les vraies données des prochaines maraudes */}
            {['Maraude A', 'Maraude B', 'Maraude C'].map((maraude, index) => (
              <Box key={index} p={4} bg={bg} borderRadius="md" width="full">
                <Text fontWeight="bold">{maraude}</Text>
                <Text>Date: à venir</Text>
              </Box>
            ))}
          </VStack>
        </Box>

        <Box bg={bg} p={6} borderRadius="md" boxShadow="md" mb={6}>
          <Heading as="h4" size="lg" mb={4}>
            Documents utiles
          </Heading>
          <VStack align="start" spacing={4}>
            {/* Exemple statique, vous devez remplacer par les vrais documents */}
            {['Document A', 'Document B', 'Document C'].map((doc, index) => (
              <HStack key={index} p={4} bg={bg} borderRadius="md" width="full">
                <Avatar>
                  <span role="img" aria-label="document">📄</span>
                </Avatar>
                <Text>{doc}</Text>
              </HStack>
            ))}
          </VStack>
        </Box>

        <Box bg={bg} p={6} borderRadius="md" boxShadow="md" mb={6}>
          <Heading as="h4" size="lg" mb={4}>
            Notifications
          </Heading>
          <VStack align="start" spacing={4}>
            {/* Exemple statique, vous devez remplacer par les vraies notifications */}
            {['Notification A', 'Notification B', 'Notification C'].map((notif, index) => (
              <Box key={index} p={4} bg={bg} borderRadius="md" width="full">
                <Text>{notif}</Text>
              </Box>
            ))}
          </VStack>
        </Box>
      </Container>
    </Layout>
  );
};

export default MonEspace;
