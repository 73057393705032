import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Textarea, VStack, Select, HStack, Table, Tbody, Tr, Td, Thead, Th } from '@chakra-ui/react';
import axiosInstance from '../common/utils/axiosConfig';
import { useParams, useNavigate } from 'react-router-dom';

const DebriefMaraude = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    conditionMeteo: '',
    moralEquipe: '',
    ambianceRue: '',
    faitsNotables: '',
    heureFin: '',
    materiel: [],
  });

  const [materielItem, setMaterielItem] = useState({ name: '', nb: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleMaterielChange = (e) => {
    const { name, value } = e.target;
    setMaterielItem({ ...materielItem, [name]: value });
  };

  const addMateriel = () => {
    setFormData({ ...formData, materiel: [...formData.materiel, materielItem] });
    setMaterielItem({ name: '', nb: '' });
  };

  const updateMateriel = (index, key, value) => {
    const updatedMateriel = formData.materiel.map((item, i) =>
      i === index ? { ...item, [key]: value } : item
    );
    setFormData({ ...formData, materiel: updatedMateriel });
  };

  const removeMateriel = (index) => {
    const updatedMateriel = formData.materiel.filter((_, i) => i !== index);
    setFormData({ ...formData, materiel: updatedMateriel });
  };

  const handleSubmit = async () => {
    await axiosInstance.put(`/api/maraude/maraude/${id}/debrief`, formData);
    navigate(`/maraude/finish/${id}`);
  };

  return (
    <VStack spacing={4} align="stretch">
      <FormControl>
        <FormLabel>Condition Météo</FormLabel>
        <Select name="conditionMeteo" value={formData.conditionMeteo} onChange={handleChange}>
          <option value="Bonne">Bonne</option>
          <option value="Mauvaise">Mauvaise</option>
          <option value="Froid">Froid</option>
          <option value="Pluie">Pluie</option>
          <option value="Canicule">Canicule</option>
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>Moral de l'Équipe</FormLabel>
        <Select name="moralEquipe" value={formData.moralEquipe} onChange={handleChange}>
          <option value="Bon">Bon</option>
          <option value="Neutre">Neutre</option>
          <option value="Mauvais">Mauvais</option>
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>Ambiance de la Rue</FormLabel>
        <Select name="ambianceRue" value={formData.ambianceRue} onChange={handleChange}>
          <option value="Bon">Bon</option>
          <option value="Neutre">Neutre</option>
          <option value="Mauvaise">Mauvaise</option>
          <option value="Agitée">Agitée</option>
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel>Faits Notables</FormLabel>
        <Textarea name="faitsNotables" value={formData.faitsNotables} onChange={handleChange} />
      </FormControl>
      <FormControl>
        <FormLabel>Heure de Fin</FormLabel>
        <Input type="time" name="heureFin" value={formData.heureFin} onChange={handleChange} />
      </FormControl>
      <Box>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Matériel</Th>
              <Th>Nb</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {formData.materiel.map((item, index) => (
              <Tr key={index}>
                <Td>
                  <Input value={item.name} onChange={(e) => updateMateriel(index, 'name', e.target.value)} />
                </Td>
                <Td>
                  <Input type="number" value={item.nb} onChange={(e) => updateMateriel(index, 'nb', e.target.value)} />
                </Td>
                <Td>
                  <Button onClick={() => removeMateriel(index)}>X</Button>
                </Td>
              </Tr>
            ))}
            <Tr>
              <Td>
                <Input placeholder="Nom du matériel" name="name" value={materielItem.name} onChange={handleMaterielChange} />
              </Td>
              <Td>
                <Input placeholder="Nb" type="number" name="nb" value={materielItem.nb} onChange={handleMaterielChange} />
              </Td>
              <Td>
                <Button onClick={addMateriel}>Ajouter</Button>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Button onClick={handleSubmit}>Valider Débriefing</Button>
    </VStack>
  );
};

export default DebriefMaraude;
