import React, { useEffect, useState } from 'react';
import { Box, VStack, HStack, Spinner, Button, Heading, Flex, Link } from '@chakra-ui/react';
import Layout from '../common/Layout';
import ArticleList from './Articles/ArticleList';
import ArticleNewForm from './Articles/ArticleNewForm';
import SearchBar from './SearchBar';
import axiosInstance from '../common/utils/axiosConfig';

const themes = ["Culture", "Asso", "Nouvel Arrivant", "Procedure", "Soins et Secours"];

function Wiki() {
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterTag, setFilterTag] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const articlesPerPage = 10;

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axiosInstance.get('/api/wiki/articles', {
          params: {
            tag: filterTag,
            title: searchTerm,
            page,
            limit: articlesPerPage,
          },
        });
        setArticles(response.data.articles);
        setTotalPages(response.data.totalPages);
        setIsLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des articles:", error);
        setIsLoading(false);
      }
    };

    fetchArticles();
  }, [searchTerm, filterTag, page]);

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Layout>
      <Box p={4} height="100vh" overflowY="auto">
        <VStack spacing={4} align="stretch">
          <Heading as="h1" size="xl" mb={4}>Wiki</Heading>
          <Link href="/wiki/livret" fontSize="xl" fontWeight="bold" color="teal.500" isExternal>
            🌟 Notre Livret D'accueil 2024 est dispo ici 🌟
          </Link>
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} themes={themes} setFilterTag={setFilterTag} />
          <ArticleList articles={articles} />
          <HStack justify="center" mt={4}>
            {Array.from({ length: totalPages }, (_, i) => (
              <Button key={i + 1} onClick={() => setPage(i + 1)} mx={1}>
                {i + 1}
              </Button>
            ))}
          </HStack>
          <ArticleNewForm />
        </VStack>
      </Box>
    </Layout>
  );
}

export default Wiki;