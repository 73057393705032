import React from 'react';
import { Box, Heading, Button, Text, Grid, VStack, HStack } from '@chakra-ui/react';
import { format, startOfMonth, endOfMonth, addDays, isSameMonth } from 'date-fns';

const MaraudeScheduler = ({ maraudes, user, registerUser, unregisterUser }) => {
  const [currentMonth, setCurrentMonth] = React.useState(new Date());

  const nextMonth = () => {
    setCurrentMonth(prev => addDays(endOfMonth(prev), 1));
  };

  const prevMonth = () => {
    setCurrentMonth(prev => startOfMonth(addDays(startOfMonth(prev), -1)));
  };

  const renderMaraudes = (date) => {
    const dayMaraudes = maraudes.filter(maraude => {
      const maraudeDate = new Date(maraude.date);
      return isSameMonth(maraudeDate, currentMonth) && maraudeDate.getDate() === date.getDate();
    });

    return dayMaraudes.map(maraude => (
      <Box key={maraude._id}
           bg={maraude.equipe.some(membre => membre._id === user._id) ? 'blue.500' : 'gray.500'}
           color="white"
           p={2} m={1} borderRadius="md">
        <Text>{maraude.itineraire}</Text>
        <Text>{`Inscrits: ${maraude.equipe.length} / ${maraude.nbPersonnesAttendues}`}</Text>
        {maraude.equipe.some(membre => membre._id === user._id) ? (
          <Button size="sm" colorScheme="red" onClick={() => unregisterUser(maraude._id)}>Se désinscrire</Button>
        ) : (
          <Button size="sm" colorScheme="blue" onClick={() => registerUser(maraude._id)}>S'inscrire</Button>
        )}
      </Box>
    ));
  };

  const generateCalendar = () => {
    const start = startOfMonth(currentMonth);
    const end = endOfMonth(currentMonth);
    let day = start;
    const weeks = [];

    while (day <= end) {
      const week = [addDays(day, (2 - day.getDay() + 7) % 7), addDays(day, (3 - day.getDay() + 7) % 7)];
      weeks.push(week);
      day = addDays(day, 7);
    }

    return weeks;
  };

  return (
    <Box>
      <Heading as="h4" size="md" mb={4}>
        Maraude Scheduler
      </Heading>
      <Box mb={4}>
        <Button onClick={prevMonth}>Précédent</Button>
        <Button onClick={nextMonth} ml={2}>Suivant</Button>
      </Box>
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        {generateCalendar().map((week, index) => (
          <React.Fragment key={index}>
            {week.map((day, i) => (
              <Box key={i} p={2} borderWidth="1px" borderRadius="md" display={isSameMonth(day, currentMonth) ? 'block' : 'none'}>
                <Heading size="sm">{format(day, 'eeee dd/MM')}</Heading>
                <VStack>
                  {renderMaraudes(day)}
                </VStack>
              </Box>
            ))}
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

export default MaraudeScheduler;
