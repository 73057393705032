import React from 'react';
import { Box, Heading, Text, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
import '../assets/Counter.css';

const Counter = () => (
  <Box
    p={5}
    shadow="md"
    borderWidth="1px"
    borderRadius="lg"
    bg={useColorModeValue('white', 'gray.700')}
  >
    <Heading fontSize="xl" mb={4}>
      Statistiques
    </Heading>
    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
      <Box textAlign="center">
        <Text fontSize="3xl">75</Text>
        <Text>Rencontres ce mois-ci</Text>
      </Box>
      <Box textAlign="center">
        <Text fontSize="3xl">68</Text>
        <Text>Soins</Text>
      </Box>
      <Box textAlign="center">
        <Text fontSize="3xl">80</Text>
        <Text>Heures de bénévolat</Text>
      </Box>
    </SimpleGrid>
  </Box>
);

export default Counter;
