import React, { useEffect, useState } from "react";
import axiosInstance from "../../common/utils/axiosConfig";
import discordLogo from "../assets/discord-logo.png";
import calendarIcon from "../assets/calendar-icon.png";
import {
  Button,
  Card,
  CardBody,
  Heading,
  Text,
  CircularProgress,
  Grid,
  Box,
  VStack,
  Image,
  HStack,
} from "@chakra-ui/react";
import { calendar } from "../../common/utils/calendar";
import { useNavigate } from 'react-router-dom';
import MaraudeScheduler from './MaraudeScheduler';

function InscriptionMaraude() {
  const [maraudes, setMaraudes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [message, setMessage] = useState('');
  const [usersData, setUsersData] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [viewMode, setViewMode] = useState('list'); // Ajouter un état pour gérer la vue

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setMessage('Utilisateur non authentifié');
        return;
      }

      try {
        const userResponse = await axiosInstance.get('/api/users/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = userResponse.data;
        setUser(userData);

        const maraudeResponse = await axiosInstance.get('/api/maraude/maraude/open');
        if (maraudeResponse.data && Array.isArray(maraudeResponse.data.Maraude)) {
          setMaraudes(maraudeResponse.data.Maraude);
        } else {
          setMaraudes([]);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Erreur lors de la récupération des données utilisateur ou des maraudes:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchUserData = async (userId) => {
    if (!usersData[userId]) {
      try {
        const response = await axiosInstance.get(`/api/users/${userId}`);
        setUsersData((prevData) => ({
          ...prevData,
          [userId]: response.data,
        }));
      } catch (error) {
        console.error('Erreur lors de la récupération des données utilisateur:', error);
      }
    }
  };

  const navigate = useNavigate();
  const registerUser = async (maraudeId) => {
    try {
      const requestBody = { userId: user._id };
      const response = await axiosInstance.put(`/api/maraude/maraude/${maraudeId}/register`, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(`Utilisateur ${user._id} inscrit à la maraude ${maraudeId} avec succès.`);
      console.log('Réponse du serveur:', response);

      // Vérifiez que la réponse indique un succès avant de recharger la page
      if (response.status === 200) {
        // Rediriger vers la même page pour forcer le rechargement
        navigate(0);
      } else {
        console.error('Erreur inattendue dans la réponse:', response.data);
        setErrorMessage('Une erreur est survenue lors de l\'inscription à la maraude.');
      }
    } catch (error) {
      console.error("Erreur lors de la requête HTTP PUT:", error);
      setErrorMessage('Une erreur est survenue lors de l\'inscription à la maraude.');
    }
  };

  const unregisterUser = async (maraudeId) => {
    try {
      const requestBody = { userId: user._id };
      await axiosInstance.put(`/api/maraude/maraude/${maraudeId}/unregister`, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(`Utilisateur ${user._id} désinscrit de la maraude ${maraudeId} avec succès.`);
      window.location.reload();  // Recharge la page pour refléter les changements
    } catch (error) {
      console.error("Erreur lors de la requête HTTP PUT:", error);
    }
  };

  useEffect(() => {
    if (maraudes.length > 0) {
      maraudes.forEach(maraude => {
        maraude.equipe.forEach(member => {
          fetchUserData(member._id);
        });
      });
    }
  }, [maraudes]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box my={4}>
      <Heading as="h4" size="md" mb={4}>
        Inscription maraude
      </Heading>
      <Button onClick={() => setViewMode(viewMode === 'list' ? 'calendar' : 'list')}>
        {viewMode === 'list' ? 'Voir en calendrier' : 'Voir en liste'}
      </Button>
      {viewMode === 'list' ? (
        maraudes.length === 0 ? (
          <Text>Pas de maraudes disponibles pour le moment.</Text>
        ) : (
          <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={6}>
            {maraudes.map(
              (maraude) =>
                !maraude.finie && (
                  <Card key={maraude._id}>
                    <CardBody>
                      <Heading as="h6" size="sm" mb={2}>
                        Maraude : {maraude.itineraire} {new Date(maraude.date).toLocaleDateString()}
                      </Heading>
                      <Text mb={1}>Responsable : {maraude.responsable || 'Non défini'}</Text>
                      <Text mb={1}>Équipe :</Text>
                      <Box as="ul" pl={4}>
                        {maraude.equipe.map((member, index) => (
                          <Box as="li" key={index}>
                            {usersData[member._id] && usersData[member._id].fichesAdherent.length > 0 ? (
                              <>
                                {usersData[member._id].fichesAdherent[0].surname} {usersData[member._id].fichesAdherent[0].name} ({usersData[member._id].fichesAdherent[0].grade})
                              </>
                            ) : (
                              <>{member.email}</>
                            )}
                          </Box>
                        ))}
                      </Box>
                      <Text mb={2}>Inscrits: {maraude.equipe.length} / {maraude.nbPersonnesAttendues}</Text>
                      <HStack spacing={4}>
                        <a
                          href={`https://discordapp.com/channels/${maraude.idDiscord}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image
                            src={discordLogo}
                            alt="Discord"
                            boxSize="30px"
                          />
                        </a>
                        {maraude.equipe.some(membre => membre._id === user._id) ? (
                          <Button
                            variant="solid"
                            colorScheme="red"
                            onClick={() => unregisterUser(maraude._id)}
                          >
                            Se désinscrire
                          </Button>
                        ) : (
                          <Button
                            variant="solid"
                            colorScheme="blue"
                            onClick={() => registerUser(maraude._id)}
                          >
                            S'inscrire
                          </Button>
                        )}
                        <Button variant="outline" colorScheme="blue" onClick={() => calendar(maraude)}>
                          <Image
                            src={calendarIcon}
                            alt="Calendrier"
                            boxSize="30px"
                          />
                        </Button>
                      </HStack>
                    </CardBody>
                  </Card>
                )
            )}
          </Grid>
        )
      ) : (
        <MaraudeScheduler maraudes={maraudes} user={user} registerUser={registerUser} unregisterUser={unregisterUser} />
      )}
    </Box>
  );
}

export default InscriptionMaraude;
