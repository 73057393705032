import React, { useState, useEffect } from 'react';
import axiosInstance from '../../common/utils/axiosConfig';
import {
  Box,
  Button,
  Container,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Heading,
} from '@chakra-ui/react';
import Layout from '../../common/Layout';

const MaraudeStatistics = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosInstance.get('/api/maraude/statistics')
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner />
      </Box>
    );
  }

  return (
    <Layout>
      <Container maxW="container.xl" py={5}>
        <Heading mb={4}>Statistiques des Maraudes</Heading>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Lieu</Th>
              <Th>Date</Th>
              <Th>Effectifs</Th>
              <Th>Soins</Th>
              <Th>Suivis</Th>
              <Th>Rencontres</Th>
              <Th>Orientation Dispensaire</Th>
              <Th>Orientation PASS</Th>
              <Th>Orientation CCAS</Th>
              <Th>Temps de Présence</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, index) => (
              <Tr key={index}>
                <Td>{row.Lieu}</Td>
                <Td>{row.Date}</Td>
                <Td>{row.Effectifs}</Td>
                <Td>{row.Soins}</Td>
                <Td>{row.Suivis}</Td>
                <Td>{row.Rencontres}</Td>
                <Td>{row.OrientationDispenser}</Td>
                <Td>{row.OrientationPASS}</Td>
                <Td>{row.OrientationCCAS}</Td>
                <Td>{row.TempsDePresence}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Container>
    </Layout>
  );
};

export default MaraudeStatistics;
