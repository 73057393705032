import React, { useEffect, useState } from 'react';
import { Box, Button, Text, VStack, Flex, useBreakpointValue } from '@chakra-ui/react';
import axiosInstance from '../common/utils/axiosConfig';
import { useNavigate } from 'react-router-dom';
import Layout from '../common/Layout';

const OpenMaraudeList = () => {
  const [maraudes, setMaraudes] = useState([]);
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const fetchMaraudes = async () => {
      try {
        const response = await axiosInstance.get('/api/maraude/maraude/open');
        setMaraudes(response.data.Maraude || []);
      } catch (error) {
        console.error('Failed to fetch maraudes:', error);
      }
    };
    fetchMaraudes();
  }, []);

  return (
    <Layout>
      <Flex direction="column" align="center" p={4} overflowY="auto" maxHeight="100vh">
        <VStack spacing={4} width="100%">
          {maraudes.map((maraude) => (
            <Box key={maraude._id} p={4} borderWidth="1px" borderRadius="md" width="100%">
              <Text><strong>Date:</strong> {new Date(maraude.date).toLocaleDateString()}</Text>
              <Text><strong>Itinéraire:</strong> {maraude.itineraire}</Text>
              <Text><strong>Responsable:</strong> {maraude.responsable || 'Non spécifié'}</Text>
              <Button mt={2} onClick={() => navigate(`/maraude/start/${maraude._id}`)}>Démarrer Maraude</Button>
            </Box>
          ))}
        </VStack>
      </Flex>
    </Layout>
  );
};

export default OpenMaraudeList;
