// src/components/Fiches/Maraudes/FicheMaraudeList.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../../common/utils/axiosConfig';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spinner,
  Text,
  useColorModeValue,
  Heading,
} from '@chakra-ui/react';
import Layout from '../../common/Layout';

const FicheMaraudeList = () => {
  const [maraudes, setMaraudes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMaraudes = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axiosInstance.get('/api/fichesMaraudes');
        setMaraudes(response.data); // Assurez-vous que response.data contient bien la liste des maraudes
      } catch (error) {
        setError('Erreur lors de la récupération des données');
      } finally {
        setLoading(false);
      }
    };

    fetchMaraudes();
  }, []);

  return (
    <Layout>
      <Container maxW="container.md" py={5}>
        <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={useColorModeValue('white', 'gray.700')}>
          <Heading mb={4}>Liste des Maraudes</Heading>
          {loading ? (
            <Spinner />
          ) : error ? (
            <Text color="red.500">{error}</Text>
          ) : (
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Date</Th>
                  <Th>Itinéraire</Th>
                  <Th>Responsable</Th>
                  <Th>Équipe</Th>
                  <Th>Nombre d'inscrits</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {maraudes.map((maraude) => (
                  <Tr key={maraude._id}>
                    <Td>{new Date(maraude.date).toLocaleDateString()}</Td>
                    <Td>{maraude.itineraire || 'N/A'}</Td>
                    <Td>{maraude.responsable || 'N/A'}</Td>
                    <Td>{maraude.equipe.map(membre => `${membre.surname} ${membre.name}`).join(', ')}</Td>
                    <Td>{maraude.nbInscrits || 0}</Td>
                    <Td>
                      <Link to={`/fiches/maraudes/${maraude._id}`}>
                        <Button colorScheme="teal" size="sm">Détails</Button>
                      </Link>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </Box>
      </Container>
    </Layout>
  );
};

export default FicheMaraudeList;
