import React from 'react';
import AppRoutes from './Routes'; // Importez le composant AppRoutes

function App() {
  return (
    <div className="App">
      <AppRoutes /> {/* Utilisez le composant AppRoutes pour définir les routes de votre application */}
    </div>
  );
}

export default App;
