import React from "react";
import { Button, Box, Container, Grid } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Layout from "../common/Layout";
import Appel from "./Maraude/AppelMaraude";
import InscriptionMaraude from "./Maraude/InscriptionMaraude";

const GestionMaraude = () => {
  return (
    <Layout>
      <Container maxW="lg" py={4}>
        <Box mb={4} textAlign="right">
          <Link to="/maraude/new">
            <Button colorScheme="blue">Créer une maraude</Button>
          </Link>
        </Box>
        <Grid templateColumns={{ base: "1fr", md: "2fr 1fr" }} gap={4}>
          <Box>
            <InscriptionMaraude />
          </Box>
          <Box>
            <Appel />
          </Box>
        </Grid>
      </Container>
    </Layout>
  );
}

export default GestionMaraude;
