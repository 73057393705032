import React, { useEffect, useState } from "react";
import axiosInstance from "../../common/utils/axiosConfig";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import formatDate from "../../common/formatdate";

const userID = "6632c33bf663cac3afe571c3";

const initialState = {
  date: new Date().toISOString().split("T")[0],
  itineraire: "",
  equipe: [],
  responsable: "",
  presence: [""],
  depart: new Date().toISOString().split("T")[1],
  retour: new Date().toISOString().split("T")[1],
  amplitude: 0,
  appel115: new Date().toISOString().split("T")[1],
  deroulement: {
    soins: 0,
    suivis: 0,
    rencontres: 0,
    orientationDispensaire: 0,
    orientationPass: 0,
    orientationCCAS: 0,
  },
  materiel: [""],
  faitsNotables: "",
  finie: false,
  idDiscord: "1217447954260951150",
  nbInscrits: 0,
  moralRencontres: "neutre",
  moralEquipe: "neutre",
  conditionMeteo: "neutre",
};

function MaraudeReport() {
  const [maraudeData, setMaraudeData] = useState(initialState);
  const [selectedMaraudeId, setSelectedMaraudeId] = useState("");
  const [maraudes, setMaraudes] = useState([]);
  const [adherents, setAdherents] = useState([]);
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const updateMaraudeData = async (data) => {
    try {
      const response = await axiosInstance.put(`/api/maraude/maraude/${data.id}`, data);
      console.log("Données mises à jour avec succès :", response.data);
      navigate(`/maraude/${data.id}`);
    } catch (error) {
      console.error("Erreur lors de la mise à jour des données :", error);
    }
  };

  const fetchMaraudes = async () => {
    try {
      const response = await axiosInstance.get(`/api/maraude/maraude?fini=false`);
      if (response.data && Array.isArray(response.data.Maraude)) {
        setMaraudes(response.data.Maraude);
      } else {
        console.error("Erreur : La réponse de l'API n'est pas un tableau ou ne contient pas la clé 'Maraude'");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des maraudes :", error);
    }
  };

  useEffect(() => {
    fetchMaraudes();
  }, []);

  useEffect(() => {
    const fetchAdherents = async () => {
      try {
        const response = await axiosInstance.get(`/api/fichesAdherents`);
        if (response.data && Array.isArray(response.data.fichesAdherents)) {
          setAdherents(response.data.fichesAdherents);
        } else {
          console.error("Erreur : La réponse de l'API des adhérents n'est pas un tableau :", response.data);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des adhérents :", error);
      }
    };

    fetchAdherents();
  }, []);

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMaraudeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddToList = (listName) => {
    setMaraudeData((prevData) => ({
      ...prevData,
      [listName]: [...prevData[listName], ""],
    }));
  };

  const handleRemoveFromList = (listName, index) => {
    setMaraudeData((prevData) => ({
      ...prevData,
      [listName]: prevData[listName].filter((_, i) => i !== index),
    }));
  };

  const handleSave = () => {
    updateMaraudeData(maraudeData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateMaraudeData(maraudeData);
  };

  const handleAdherentChange = (e, name) => {
    const value = e.target.value;
    setMaraudeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeList = (e, listName, index) => {
    const { value } = e.target;
    setMaraudeData((prevData) => {
      const listCopy = [...prevData[listName]];
      listCopy[index] = value;
      return {
        ...prevData,
        [listName]: listCopy,
      };
    });
  };

  const handleMaraudeChange = (event) => {
    const selectedId = event.target.value;
    setSelectedMaraudeId(selectedId);

    const selectedMaraude = maraudes.find((maraude) => maraude._id === selectedId);
    if (selectedMaraude) {
      setMaraudeData({
        ...initialState,
        id: selectedMaraude._id,
        date: formatDate(selectedMaraude.date),
        itineraire: selectedMaraude.itineraire,
        responsable: selectedMaraude.responsable,
        presence: selectedMaraude.presence,
        depart: selectedMaraude.depart,
        retour: selectedMaraude.retour,
        amplitude: selectedMaraude.amplitude,
        appel115: selectedMaraude.appel115,
        deroulement: {
          soins: selectedMaraude.soins,
          suivis: selectedMaraude.suivis,
          rencontres: selectedMaraude.rencontres,
          orientationDispensaire: selectedMaraude.orientationDispensaire,
          orientationPass: selectedMaraude.orientationPass,
          orientationCCAS: selectedMaraude.orientationCCAS,
        },
        materiel: selectedMaraude.materiel,
        faitsNotables: selectedMaraude.faitsNotables,
        finie: selectedMaraude.finie,
        idDiscord: selectedMaraude.idDiscord,
        nbInscrits: selectedMaraude.nbInscrits,
        moralRencontres: selectedMaraude.moralRencontres,
        moralEquipe: selectedMaraude.moralEquipe,
        conditionMeteo: selectedMaraude.conditionMeteo,
      });
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        {step === 0 && (
          <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
            <Typography variant="h4" gutterBottom>
              Départ
            </Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel>Maraudes non terminées</InputLabel>
              <Select value={selectedMaraudeId} onChange={handleMaraudeChange}>
                <MenuItem value="">Sélectionnez une maraude</MenuItem>
                {maraudes.map((maraude) => (
                  <MenuItem key={maraude._id} value={maraude._id}>
                    {maraude.itineraire} - {formatDate(maraude.date)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              label="Date"
              type="date"
              name="date"
              value={maraudeData.date}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Itinéraire"
              type="text"
              name="itineraire"
              value={maraudeData.itineraire}
              onChange={handleChange}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Responsable</InputLabel>
              <Select
                name="responsable"
                value={maraudeData.responsable}
                onChange={(e) => handleAdherentChange(e, "responsable")}
              >
                <MenuItem value="">Sélectionnez un responsable</MenuItem>
                {adherents.map((adherent) => (
                  <MenuItem key={adherent._id} value={adherent.name}>
                    {adherent.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              margin="normal"
              label="Départ"
              type="time"
              name="depart"
              value={maraudeData.depart}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Appel 115"
              type="time"
              name="appel115"
              value={maraudeData.appel115}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
            <Typography variant="h6" gutterBottom>
              Présence
            </Typography>
            {maraudeData.presence.map((pres, index) => (
              <Box key={index} display="flex" alignItems="center" mb={2}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Membre</InputLabel>
                  <Select
                    value={pres}
                    onChange={(e) => handleChangeList(e, "presence", index)}
                  >
                    <MenuItem value="">Sélectionnez un membre</MenuItem>
                    {adherents.map((adherent) => (
                      <MenuItem
                        key={adherent._id}
                        value={`${adherent.name} ${adherent.surname} (${adherent.grade})`}
                      >
                        {`${adherent.name} ${adherent.surname} (${adherent.grade})`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  color="secondary"
                  onClick={() => handleRemoveFromList("presence", index)}
                >
                  Supprimer
                </Button>
              </Box>
            ))}
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleAddToList("presence")}
            >
              Ajouter un membre
            </Button>
            <Box display="flex" justifyContent="space-between" mt={4}>
              <Button color="primary" variant="contained" onClick={handleSave}>
                Sauvegarder
              </Button>
              <Button color="primary" variant="contained" onClick={handleNext}>
                Suivant
              </Button>
            </Box>
          </Paper>
        )}
        {step === 1 && (
          <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
            <Typography variant="h4" gutterBottom>
              Déroulement
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              label="Soins"
              type="number"
              name="soins"
              value={maraudeData.deroulement.soins}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Suivis"
              type="number"
              name="suivis"
              value={maraudeData.deroulement.suivis}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Rencontres"
              type="number"
              name="rencontres"
              value={maraudeData.deroulement.rencontres}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Orientation Dispensaire"
              type="number"
              name="orientationDispensaire"
              value={maraudeData.deroulement.orientationDispensaire}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Orientation PASS"
              type="number"
              name="orientationPASS"
              value={maraudeData.deroulement.orientationPASS}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Orientation CCAS"
              type="number"
              name="orientationCCAS"
              value={maraudeData.deroulement.orientationCCAS}
              onChange={handleChange}
            />
            <Box display="flex" justifyContent="space-between" mt={4}>
              <Button color="primary" variant="contained" onClick={handleSave}>
                Sauvegarder
              </Button>
              <Button color="primary" variant="contained" onClick={handleBack}>
                Précédent
              </Button>
              <Button color="primary" variant="contained" onClick={handleNext}>
                Suivant
              </Button>
            </Box>
          </Paper>
        )}
        {step === 2 && (
          <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
            <Typography variant="h4" gutterBottom>
              Débriefing
            </Typography>
            <TextField
              fullWidth
              margin="normal"
              label="Faits notables"
              multiline
              rows={4}
              name="faitsNotables"
              value={maraudeData.faitsNotables}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Retour"
              type="time"
              name="retour"
              value={maraudeData.retour}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Moral des rencontres</InputLabel>
              <Select
                name="moralRencontres"
                value={maraudeData.moralRencontres}
                onChange={handleChange}
              >
                <MenuItem value="neutre">Neutre</MenuItem>
                <MenuItem value="bon">Bon</MenuItem>
                <MenuItem value="mauvais">Mauvais</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Moral de l'équipe</InputLabel>
              <Select
                name="moralEquipe"
                value={maraudeData.moralEquipe}
                onChange={handleChange}
              >
                <MenuItem value="neutre">Neutre</MenuItem>
                <MenuItem value="bon">Bon</MenuItem>
                <MenuItem value="mauvais">Mauvais</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Condition météo</InputLabel>
              <Select
                name="conditionMeteo"
                value={maraudeData.conditionMeteo}
                onChange={handleChange}
              >
                <MenuItem value="neutre">Neutre</MenuItem>
                <MenuItem value="ensoleille">Ensoleillé</MenuItem>
                <MenuItem value="pluvieux">Pluvieux</MenuItem>
                <MenuItem value="venteux">Venteux</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="h6" gutterBottom>
              Matériel
            </Typography>
            {maraudeData.materiel.map((materiel, index) => (
              <Box key={index} display="flex" alignItems="center" mb={2}>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Matériel"
                  type="text"
                  value={materiel}
                  onChange={(e) => handleChangeList(e, "materiel", index)}
                />
                <Button
                  color="secondary"
                  onClick={() => handleRemoveFromList("materiel", index)}
                >
                  Supprimer
                </Button>
              </Box>
            ))}
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleAddToList("materiel")}
            >
              Ajouter du matériel
            </Button>
            <Box display="flex" justifyContent="space-between" mt={4}>
              <Button color="primary" variant="contained" onClick={handleSave}>
                Sauvegarder
              </Button>
              <Button color="primary" variant="contained" onClick={handleBack}>
                Précédent
              </Button>
              <Button color="primary" variant="contained" type="submit">
                Soumettre
              </Button>
            </Box>
          </Paper>
        )}
      </form>
    </Container>
  );
}

export default MaraudeReport;
