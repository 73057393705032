import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../common/utils/axiosConfig';
import { AuthContext } from '../../../AuthContext';
import {
  Box,
  Button,
  Container,
  Input,
  Text,
  VStack,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axiosInstance.post(`/api/auth/login`, {
        email: username,
        password: password,
      });

      const { token, userId, validated } = response.data;

      if (!validated) {
        setErrorMessage("Votre compte n'a pas encore été validé. Veuillez patienter ou contacter un administrateur à contact@csor.fr.");
      } else {
        login({ token, userId });
        navigate('/dashboard');
        setUsername('');
        setPassword('');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage('Identifiants incorrects');
      } else {
        setErrorMessage('Erreur lors de la connexion');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxW="sm">
      <Box
        as="form"
        onSubmit={handleSubmit}
        boxShadow="lg"
        p={6}
        rounded="md"
        bg="white"
        mt={6}
      >
        <VStack spacing={4}>
          <Text fontSize="2xl" mb={4}>
            Connexion
          </Text>
          {errorMessage && (
            <Text color="red.500" mb={4}>
              {errorMessage}
            </Text>
          )}
          <FormControl id="email">
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </FormControl>
          <FormControl id="password">
            <FormLabel>Mot de passe</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </FormControl>
          <Button type="submit" colorScheme="blue" width="full" isLoading={isLoading}>
            Se connecter
          </Button>
        </VStack>
      </Box>
    </Container>
  );
}

export default Login;
