import React, { useState, useEffect } from 'react';
import axiosInstance from '../../common/utils/axiosConfig';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Spinner,
  VStack,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import Layout from '../../common/Layout';

const FicheAdherentList = () => {
  const [adherents, setAdherents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axiosInstance.get('/api/fichesAdherents/');
        if (response.data && Array.isArray(response.data.fichesAdherents)) {
          setAdherents(response.data.fichesAdherents);
        } else {
          setError('Les données reçues ne sont pas valides');
        }
      } catch (error) {
        setError('Erreur lors de la récupération des données');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Layout>
      <Container maxW="container.md" py={5}>
        <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={useColorModeValue('white', 'gray.700')}>
          <Heading mb={4}>Liste des Adhérents</Heading>
          {loading ? (
            <Spinner />
          ) : error ? (
            <Text color="red.500">{error}</Text>
          ) : (
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Nom</Th>
                  <Th>Prénom</Th>
                  <Th>Grade</Th>
                  <Th>Téléphone</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {adherents.map((adherent) => (
                  <Tr key={adherent._id}>
                    <Td>{adherent.name}</Td>
                    <Td>{adherent.surname}</Td>
                    <Td>{adherent.grade}</Td>
                    <Td>{adherent.phone}</Td>
                    <Td>
                      <Link to={`/fiches/adherents/${adherent._id}`}>
                        <Button colorScheme="teal" size="sm">Détails</Button>
                      </Link>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </Box>
      </Container>
    </Layout>
  );
};

export default FicheAdherentList;
