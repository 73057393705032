import React from 'react';
import { Box, Flex, Text, Icon, useColorModeValue } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import {
  FiHome, FiLogOut
} from 'react-icons/fi';
import {
  BiCog
} from 'react-icons/bi';
import {
  GiArchiveRegister
} from 'react-icons/gi';
import {
  AiOutlineFileSearch
} from 'react-icons/ai';

const SidebarMobile = ({ handleToggleSidebar }) => {
  const bg = useColorModeValue('rgba(255, 255, 255, 0.45)', 'rgba(0, 0, 0, 0.45)');
  const color = useColorModeValue('gray.800', 'white');

  return (
    <Box
      position="fixed"
      bottom="0"
      left="0"
      width="100%"
      backgroundColor={bg}
      color={color}
      boxShadow="lg"
      zIndex="1000"
    >
      <Flex justify="space-around" p="2">
        <SidebarItem icon={FiHome} to="/dashboard" label="Accueil" />
        <SidebarItem icon={GiArchiveRegister} to="/maraude" label="Planning Maraude" />
        <SidebarItem icon={AiOutlineFileSearch} to="/fiches" label="Fiches" />
        <SidebarItem icon={BiCog} to="/wiki" label="Wiki" />
        <SidebarItem icon={GiArchiveRegister} to="/maraude/otg" label="OTG" />
        <SidebarItem icon={FiLogOut} to="/logout" label="Déconnexion" />
      </Flex>
    </Box>
  );
};

const SidebarItem = ({ icon, to, label }) => {
  return (
    <Link to={to}>
      <Flex direction="column" align="center">
        <Icon as={icon} w={6} h={6} />
        <Text fontSize="xs">{label}</Text>
      </Flex>
    </Link>
  );
};

export default SidebarMobile;
