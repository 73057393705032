// src/components/Wiki/Articles/Article.js
import React from 'react';
import { Box, Heading, Text, Tag, VStack } from '@chakra-ui/react';

const Article = ({ article }) => {
  return (
    <Box p={4} borderWidth={1} borderRadius="lg" shadow="md" bg="white">
      <VStack align="start" spacing={2}>
        <Heading size="md">{article.title}</Heading>
        <Text><strong>Auteur:</strong> {article.author}</Text>
        <Text>
          <strong>Tags:</strong>
          {article.tags.map(tag => (
            <Tag key={tag} mr={2}>{tag}</Tag>
          ))}
        </Text>
        <Text>{article.content}</Text>
        <Text><small><strong>Créé le:</strong> {new Date(article.createdAt).toLocaleDateString()}</small></Text>
        <Text><small><strong>Mis à jour le:</strong> {new Date(article.updatedAt).toLocaleDateString()}</small></Text>
      </VStack>
    </Box>
  );
};

export default Article;
