import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import { Box } from "@chakra-ui/react";
import Home from "./components/Home/Home";
import MonEspace from "./components/vos-informations/MonEspace";
import Dashboard from "./components/Home/Dashboard";
import GestionMaraude from "./components/Gestion/GestionMaraudes";
import CreationMaraude from "./components/Gestion/Maraude/CreationMaraude";
import OTGPage from "./components/Maraude/legacy/OTG";
import Listing from "./components/Fiches/Listing";
import FicheMaraude from "./components/Fiches/Maraudes/FicheMaraude";
import FichePatient from "./components/Fiches/Patients/FichePatient";
import MaraudeList from "./components/Fiches/Maraudes/FicheMaraudeList";
import NewInter from "./components/Fiches/Interventions/FicheInterventionNew";
import FicheIntervention from "./components/Fiches/Interventions/FicheIntervention";
import Wiki from "./components/Wiki/Wiki";
import ArticleEdit from "./components/Wiki/Articles/ArticleEdit";
import Signup from "./components/Home/Login/SignUp";
import SignupConfirmation from "./components/Home/Login/SignupConfirmation";
import FicheAdherentList from "./components/Fiches/Adherents/FicheAdherentList";
import FicheAdherent from "./components/Fiches/Adherents/FicheAdherent";
import OpenMaraudeList from "./components/Maraude/OpenMaraudeList";
import StartMaraude from "./components/Maraude/StartMaraude";
import OngoingMaraude from "./components/Maraude/OngoingMaraude";
import DebriefMaraude from "./components/Maraude/DebriefMaraude";
import FinishMaraude from "./components/Maraude/FinishMaraude";
import ReviewMaraude from "./components/Maraude/ReviewMaraude";
import ReportingMaraude from "./components/Gestion/Reporting/MaraudeDataPage";
import ReportGenerationPage from "./components/Gestion/Reporting/MaraudeGenerate";
import MaraudeStatistics from "./components/Gestion/Reporting/MaraudeStatistics";
import ProtectedRoute from "./ProtectedRoute";
import Livret from "./components/Wiki/Livrets/LivretPage";

const AppRoutes = () => {
  const [menuCollapse, setMenuCollapse] = useState(true);

  return (
    <AuthProvider>
      <Router>

        <Box marginLeft={menuCollapse ? "60px" : "200px"} transition="margin-left 0.3s">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/inscription" element={<Signup />} />
            <Route path="/signup-confirmation" element={<SignupConfirmation />} />
            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/mon-espace" element={<ProtectedRoute><MonEspace /></ProtectedRoute>} />
            <Route path="/maraude" element={<ProtectedRoute><GestionMaraude /></ProtectedRoute>} />
            <Route path="/maraude/new" element={<ProtectedRoute><CreationMaraude /></ProtectedRoute>} />
            <Route path="/maraude/otg" element={<ProtectedRoute><OTGPage /></ProtectedRoute>} />
            <Route path="/maraude/:id" element={<ProtectedRoute><FicheMaraude /></ProtectedRoute>} />
            <Route path="/fiches" element={<ProtectedRoute><Listing /></ProtectedRoute>} />
            <Route path="/fiches/maraudes" element={<ProtectedRoute><MaraudeList /></ProtectedRoute>} />
            <Route path="/fiches/maraudes/:id" element={<ProtectedRoute><FicheMaraude /></ProtectedRoute>} />
            <Route path="/fiches/patients/:id" element={<ProtectedRoute><FichePatient /></ProtectedRoute>} />
            <Route path="/fiches/interventions/new" element={<ProtectedRoute><NewInter /></ProtectedRoute>} />
            <Route path="/fiches/interventions/:id" element={<ProtectedRoute><FicheIntervention /></ProtectedRoute>} />
            <Route path="/fiches/adherents" element={<ProtectedRoute><FicheAdherentList /></ProtectedRoute>} />
            <Route path="/fiches/adherents/:id" element={<ProtectedRoute><FicheAdherent /></ProtectedRoute>} />
            <Route path="/wiki" element={<ProtectedRoute><Wiki /></ProtectedRoute>} />
            <Route path="/wiki/livret" element={<ProtectedRoute><Livret /></ProtectedRoute>} />
            <Route path="/wiki/articles/:id/edit" element={<ProtectedRoute><ArticleEdit /></ProtectedRoute>} />
            <Route path="/maraude/open" element={<ProtectedRoute><OpenMaraudeList /></ProtectedRoute>} />
            <Route path="/maraude/start/:id" element={<ProtectedRoute><StartMaraude /></ProtectedRoute>} />
            <Route path="/maraude/ongoing/:id" element={<ProtectedRoute><OngoingMaraude /></ProtectedRoute>} />
            <Route path="/maraude/debrief/:id" element={<ProtectedRoute><DebriefMaraude /></ProtectedRoute>} />
            <Route path="/maraude/finish/:id" element={<ProtectedRoute><FinishMaraude /></ProtectedRoute>} />
            <Route path="/maraude/review/:id" element={<ProtectedRoute><ReviewMaraude /></ProtectedRoute>} />
            <Route path="/reporting" element={<ProtectedRoute><ReportingMaraude /></ProtectedRoute>} />
            <Route path="/reporting/statistics" element={<ProtectedRoute><MaraudeStatistics /></ProtectedRoute>} />
            <Route path="/reporting/generate" element={<ProtectedRoute><ReportGenerationPage /></ProtectedRoute>} />
          </Routes>
        </Box>
      </Router>
    </AuthProvider>
  );
};

export default AppRoutes;
