// src/components/common/formatdate.js
import React from 'react';

// Fonction pour formater la date au format dd-mm-yyyy
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export default formatDate;