// StartMaraude.js
import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, FormLabel, Input, Select, VStack, Text, IconButton } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import axiosInstance from '../common/utils/axiosConfig';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../common/Layout';
import formatDate from '../common/formatdate';

const StartMaraude = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [maraude, setMaraude] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [adherents, setAdherents] = useState([]);
  const [formData, setFormData] = useState({
    date: '',
    lieu: '',
    chef: '',
    heureDepart: '',
    heureAppel115: '',
    team: [],
  });

  const lieuxOptions = ['Ste Catherine', 'Gare', 'Lac', 'Autre'];

  useEffect(() => {
    const fetchMaraude = async () => {
      try {
        const response = await axiosInstance.get(`/api/maraude/maraude/${id}`);
        const maraudeData = response.data;

        setMaraude(maraudeData);
        setFormData({
          date: maraudeData.date ? formatDate(maraudeData.date) : '',
          lieu: maraudeData.itineraire || '',
          chef: maraudeData.responsable || '',
          heureDepart: '',
          heureAppel115: '',
          team: maraudeData.equipe ? maraudeData.equipe.map(member => member._id) : [],
        });
        setTeamMembers(maraudeData.equipe ? maraudeData.equipe.map(member => member._id) : []);
      } catch (error) {
        console.error('Error fetching maraude:', error);
      }
    };

    const fetchAdherents = async () => {
      try {
        const response = await axiosInstance.get('/api/fichesAdherents');
        setAdherents(response.data.fichesAdherents);
      } catch (error) {
        console.error('Error fetching adherents:', error);
      }
    };

    fetchMaraude();
    fetchAdherents();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddTeamMember = (e) => {
    const { value } = e.target;
    if (!teamMembers.includes(value)) {
      setTeamMembers([...teamMembers, value]);
      setFormData({ ...formData, team: [...formData.team, value] });
    }
  };

  const handleRemoveTeamMember = (memberId) => {
    const updatedTeamMembers = teamMembers.filter((id) => id !== memberId);
    setTeamMembers(updatedTeamMembers);
    setFormData({ ...formData, team: updatedTeamMembers });
  };

  const handleSubmit = async () => {
    try {
      await axiosInstance.put(`/api/maraude/maraude/${id}/start`, formData);
      navigate(`/maraude/ongoing/${id}`);
    } catch (error) {
      console.error('Error starting maraude:', error);
    }
  };

  return (
    <Layout>
      <Box p={{ base: 2, md: 4 }} maxWidth="800px" mx="auto" pb={20}>
        <VStack spacing={4} align="stretch">
          <Text fontSize="2xl" mb={4}>Démarrer Maraude</Text>
          <FormControl>
            <FormLabel>Date</FormLabel>
            <Input type="date" name="date" value={formData.date} onChange={handleChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Lieu</FormLabel>
            <Select name="lieu" value={formData.lieu} onChange={handleChange}>
              {lieuxOptions.map((lieu) => (
                <option key={lieu} value={lieu}>{lieu}</option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Chef</FormLabel>
            <Input name="chef" value={formData.chef} onChange={handleChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Heure de départ</FormLabel>
            <Input type="time" name="heureDepart" value={formData.heureDepart} onChange={handleChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Heure d'appel au 115</FormLabel>
            <Input type="time" name="heureAppel115" value={formData.heureAppel115} onChange={handleChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Ajouter des équipiers</FormLabel>
            <Select placeholder="Sélectionner un équipier" onChange={handleAddTeamMember}>
              {adherents.map((adherent) => (
                <option key={adherent._id} value={adherent._id}>
                  {adherent.name} {adherent.surname} ({adherent.grade})
                </option>
              ))}
            </Select>
          </FormControl>
          <Box>
            <Text fontWeight="bold" mt={4}>Équipiers ajoutés :</Text>
            {teamMembers.map((memberId) => {
              const member = adherents.find((adherent) => adherent._id === memberId);
              return member ? (
                <Box key={member._id} display="flex" alignItems="center" mb={2}>
                  <Text flex="1">
                    {member.name} {member.surname} ({member.grade})
                  </Text>
                  <IconButton
                    icon={<CloseIcon />}
                    size="sm"
                    onClick={() => handleRemoveTeamMember(member._id)}
                  />
                </Box>
              ) : null;
            })}
          </Box>
          <Button onClick={handleSubmit} colorScheme="teal" mb={4} mt={4}>Démarrer Maraude</Button>
        </VStack>
      </Box>
    </Layout>
  );
};

export default StartMaraude;
