import React, { useState, useEffect } from 'react';
import axiosInstance from '../common/utils/axiosConfig';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  VStack,
  HStack,
  useColorModeValue,
  Spinner,
  Heading,
} from '@chakra-ui/react';
import Layout from '../common/Layout';
import calculateAge from '../common/utils/calculateAge';
import './Listing.css';

const Listing = () => {
  const [type, setType] = useState('fichesPatients');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axiosInstance.get(`/api/${type}/`);
        if (response.data && response.data[type]) {
          setData(response.data[type]);
        } else {
          setData([]);
        }
      } catch (error) {
        setError('Erreur lors de la récupération des données');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [type]);

  const typeWithoutFiches = type.replace('fiches', '');

  const sortDataByName = () => {
    const sortedData = [...data].sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (sortOrder === 'asc') {
        return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
      } else {
        return nameA > nameB ? -1 : nameA < nameB ? 1 : 0;
      }
    });
    setData(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const filteredData = data.filter((fiche) =>
    fiche.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    fiche.surname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedData = filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <Layout>
      <VStack spacing={5} p={5} align="stretch">
        <Heading fontSize="2xl" fontWeight="bold">
          {type === 'fichesPatients' ? 'Fiches Patients' : type === 'fichesInterventions' ? 'Fiches Interventions' : 'Fiches Adhérents'}
        </Heading>
        <HStack>
          <Button onClick={() => setType('fichesPatients')}>Fiches Patients</Button>
          <Button onClick={() => setType('fichesInterventions')}>Fiches Interventions</Button>
          <Button onClick={() => setType('fichesAdherents')}>Fiches Adhérents</Button>
          <Button onClick={sortDataByName}>
            Trier par Nom {sortOrder === 'asc' ? '▲' : '▼'}
          </Button>
          <Link to={`/fiches/${typeWithoutFiches}/new`}>
            <Button>Nouveau</Button>
          </Link>
        </HStack>
        <Input
          placeholder="Recherche par nom ou prénom"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {loading ? (
          <Spinner />
        ) : error ? (
          <Text>{error}</Text>
        ) : (
          <>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Nom</Th>
                  <Th>Prénom</Th>
                  <Th>Description</Th>
                  <Th>Date de naissance</Th>
                  <Th>Âge</Th>
                  {type === 'fichesInterventions' && (
                    <>
                      <Th>Bilan</Th>
                      <Th>Date de l'intervention</Th>
                      <Th>Impliqué</Th>
                      <Th>Antécédents</Th>
                      <Th>Solution</Th>
                    </>
                  )}
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedData.map(fiche => (
                  <Tr key={fiche._id}>
                    <Td>{fiche.name}</Td>
                    <Td>{fiche.surname}</Td>
                    <Td>{fiche.description}</Td>
                    <Td>{new Date(fiche.birthdate).toLocaleDateString()}</Td>
                    <Td>{calculateAge(fiche.birthdate)}</Td>
                    {type === 'fichesInterventions' && (
                      <>
                        <Td>{fiche.bilan}</Td>
                        <Td>{new Date(fiche.date).toLocaleDateString()}</Td>
                        <Td>{fiche.implique}</Td>
                        <Td>{fiche.antecedents}</Td>
                        <Td>{fiche.solution}</Td>
                      </>
                    )}
                    <Td>
                      <Link to={`/fiches/${typeWithoutFiches}/${fiche._id}`}>
                        <Button>Détails</Button>
                      </Link>
                      <Link to={`/fiches/${typeWithoutFiches}/edit/${fiche._id}`}>
                        <Button colorScheme="yellow" size="sm" ml={2}>Modifier</Button>
                      </Link>
                      <Button colorScheme="red" size="sm" ml={2}>Supprimer</Button>
                      <Button colorScheme="blue" size="sm" ml={2}>Exporter</Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <HStack>
              {Array.from({ length: totalPages }, (_, index) => (
                <Button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  variant={currentPage === index + 1 ? 'solid' : 'outline'}
                >
                  {index + 1}
                </Button>
              ))}
            </HStack>
          </>
        )}
      </VStack>
    </Layout>
  );
};

export default Listing;
