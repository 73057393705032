import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  Heading,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../common/utils/axiosConfig';

const ArticleEdit = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [tags, setTags] = useState('');
  const [content, setContent] = useState('');
  const toast = useToast();

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}wiki/articles/${id}`);
        setArticle(response.data);
        setTitle(response.data.title);
        setAuthor(response.data.author);
        setTags(response.data.tags.join(', '));
        setContent(response.data.content);
      } catch (error) {
        console.error('Erreur lors de la récupération de l\'article:', error);
      }
    };

    fetchArticle();
  }, [id]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const requestBody = {
      title,
      author,
      tags: tags.split(',').map(tag => tag.trim()),
      content,
    };

    try {
      const response = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/api/wiki/articles/${id}`,
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        }
      );

      toast({
        title: "Article mis à jour avec succès",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'article:', error);
      toast({
        title: "Erreur lors de la mise à jour de l'article",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (!article) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box mt={8}>
      <Heading as="h2" size="lg" mb={4}>Modifier l'article</Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel htmlFor="title">Titre</FormLabel>
            <Input
              id="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="author">Auteur</FormLabel>
            <Input
              id="author"
              type="text"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="tags">Tags (séparés par des virgules)</FormLabel>
            <Input
              id="tags"
              type="text"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel htmlFor="content">Contenu</FormLabel>
            <Textarea
              id="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </FormControl>
          <Button type="submit" colorScheme="blue">Mettre à jour</Button>
        </VStack>
      </form>
    </Box>
  );
};

export default ArticleEdit;
