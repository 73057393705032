
export function calendar(maraude) {
        const icsContent = `
BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
DTSTART:${new Date(maraude.date).toISOString().replace(/-|:|\.\d+/g, '')}
DTEND:${new Date(maraude.date).toISOString().replace(/-|:|\.\d+/g, '')}
SUMMARY:Maraude ${maraude.itineraire}
DESCRIPTION: Bonjour, voici l'appel pour la maraude ! N'oubliez pas d'adopter une tenue adaptée et des chaussures fermées.
LOCATION:${maraude.itineraire}
END:VEVENT
END:VCALENDAR`;
    
        const blob = new Blob([icsContent], { type: 'text/calendar' });
        const url = URL.createObjectURL(blob);
    
        const link = document.createElement('a');
        link.href = url;
        link.download = `Maraude_${maraude.name}.ics`;
        link.click();
    
        URL.revokeObjectURL(url);
    }
    
