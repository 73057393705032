// src/components/Fiches/Interventions/NewInter.js
import React, { useState, useEffect } from "react";
import axiosInstance from "../../common/utils/axiosConfig";
import { useNavigate } from "react-router-dom";
import calculateAge from "../../common/utils/calculateAge";
import FileUpload from "../../common/utils/FtpUpload";
import {
  Box,
  Button,
  Container,
  Input,
  Select,
  Text,
  Textarea,
  VStack,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";

function NewInter() {
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    age: "",
    birthdate: "",
    description: "",
    bilan: "",
    date: "",
    photo: "",
    implique: "",
    antecedents: "",
    lieu: "",
    maraudeId: "",
    solution: "",
    idPatient: "",
  });

  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [maraudes, setMaraudes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMaraudes = async () => {
      try {
        const response = await axiosInstance.get('/api/maraude/maraude');
        if (response.data && response.data.Maraude) {
          const filteredMaraudes = response.data.Maraude.filter(
            (maraude) => !maraude.finie
          );
          setMaraudes(filteredMaraudes);
          setLoading(false);
        } else {
          console.error("Aucune maraude trouvée.");
          setLoading(false);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des maraudes:", error);
        setLoading(false);
      }
    };

    fetchMaraudes();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const searchPatient = async () => {
    const { name, surname, birthdate } = formData;
    try {
      const response = await axiosInstance.get(
        `/api/fichesPatients/search/${name || surname || birthdate}`
      );

      const updatedSearchResults = response.data.fichePatients.map((patient) => ({
        ...patient,
        birthdate: new Date(patient.birthdate).toISOString().split("T")[0],
        age: calculateAge(patient.birthdate),
      }));
      setSearchResults(updatedSearchResults);
    } catch (error) {
      console.error("Erreur lors de la recherche du patient:", error);
    }
  };

  const handlePatientSelection = (patient) => {
    setFormData({
      ...formData,
      name: patient.name,
      surname: patient.surname,
      age: calculateAge(patient.birthdate),
      birthdate: patient.birthdate,
      idPatient: patient._id,
    });
    setSelectedPatient(patient);
    setSearchResults([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let patientId = formData.idPatient;

      if (!patientId) {
        const patientResponse = await axiosInstance.post(`/api/fichesPatients`, {
          name: formData.name,
          surname: formData.surname,
          birthdate: formData.birthdate,
          description: formData.description,
        });
        patientId = patientResponse.data.fichePatient._id;
      }

      const interventionResponse = await axiosInstance.post(`/api/fichesInterventions`, {
        idPatient: patientId,
        name: formData.name,
        surname: formData.surname,
        age: formData.age,
        birthdate: formData.birthdate,
        description: formData.description,
        bilan: formData.bilan,
        date: formData.date,
        photo: formData.photo,
        implique: formData.implique,
        antecedents: formData.antecedents,
        lieu: formData.lieu,
        maraudeId: formData.maraudeId,
        solution: formData.solution,
      });

      const newInterventionId = interventionResponse.data.fichesIntervention._id;

      await axiosInstance.put(`/api/fichesPatients/${patientId}`, {
        listInterventions: [...(selectedPatient?.listInterventions || []), newInterventionId],
      });

      navigate(`/fiches/interventions/${newInterventionId}`);
    } catch (error) {
      console.error("Erreur lors de la création de la fiche d'intervention:", error);
    }
  };

  const handleFileUpload = (url) => {
    setFormData({ ...formData, photo: url });
  };

  return (
    <Container maxW="xl" py={8}>
      <Box bg={useColorModeValue('white', 'gray.700')} p={6} rounded="md" shadow="md">
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          Nouvelle fiche d'intervention
        </Text>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <Input
              placeholder="Nom"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Prénom"
              name="surname"
              value={formData.surname}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Âge"
              name="age"
              value={formData.age}
              onChange={handleChange}
              readOnly={selectedPatient !== null}
            />
            <Input
              placeholder="Date de naissance"
              name="birthdate"
              type="date"
              value={formData.birthdate}
              onChange={handleChange}
              required
              readOnly={selectedPatient !== null}
            />
            <Button onClick={searchPatient}>Rechercher</Button>
            {searchResults.length > 0 && (
              <Box w="100%">
                {searchResults.map((patient) => (
                  <Box
                    key={patient._id}
                    p={2}
                    borderWidth={1}
                    borderRadius="md"
                    cursor="pointer"
                    onClick={() => handlePatientSelection(patient)}
                  >
                    {patient.name} {patient.surname} - {patient.birthdate}
                  </Box>
                ))}
              </Box>
            )}
            <Textarea
              placeholder="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
            <Textarea
              placeholder="Bilan"
              name="bilan"
              value={formData.bilan}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Date"
              name="date"
              type="date"
              value={formData.date}
              onChange={handleChange}
              required
            />
            <Input
              placeholder="Lieu"
              name="lieu"
              value={formData.lieu}
              onChange={handleChange}
              required
            />
            <FileUpload onUpload={handleFileUpload} />
            {formData.photo && (
              <Box>
                <img src={formData.photo} alt="Uploaded" style={{ width: '100px' }} />
              </Box>
            )}
            {loading ? (
              <Spinner />
            ) : (
              <Select
                placeholder="Sélectionnez une maraude"
                name="maraudeId"
                value={formData.maraudeId}
                onChange={handleChange}
                required
              >
                {maraudes.map((maraude) => (
                  <option key={maraude._id} value={maraude._id}>
                    {maraude.name || "Maraude"} - {new Date(maraude.date).toLocaleDateString()}
                  </option>
                ))}
              </Select>
            )}
            <Input
              placeholder="Impliqué"
              name="implique"
              value={formData.implique}
              onChange={handleChange}
              required
            />
            <Textarea
              placeholder="Antécédents"
              name="antecedents"
              value={formData.antecedents}
              onChange={handleChange}
            />
            <Textarea
              placeholder="Solution"
              name="solution"
              value={formData.solution}
              onChange={handleChange}
            />
            <Input
              placeholder="ID du patient"
              name="idPatient"
              value={formData.idPatient}
              onChange={handleChange}
              readOnly={selectedPatient !== null}
            />
            <Button type="submit">Soumettre</Button>
          </VStack>
        </form>
      </Box>
    </Container>
  );
}

export default NewInter;
