import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import axiosInstance from '../../common/utils/axiosConfig';
import '../assets/NextMaraude.css';

const NextMaraude = () => {
  const [nextMaraude, setNextMaraude] = useState(null);
  const bg = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    const fetchNextMaraude = async () => {
      try {
        const response = await axiosInstance.get('/api/maraude/maraude/open');
        const maraudes = response.data.Maraude;

        // Trier les maraudes par date pour obtenir la prochaine maraude
        const sortedMaraudes = maraudes.sort((a, b) => new Date(a.date) - new Date(b.date));
        const upcomingMaraude = sortedMaraudes.find(maraude => new Date(maraude.date) >= new Date());

        setNextMaraude(upcomingMaraude);
      } catch (error) {
        console.error('Erreur lors de la récupération des maraudes ouvertes:', error);
      }
    };

    fetchNextMaraude();
  }, []);

  return (
    <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={bg}>
      <Heading fontSize="xl" mb={4}>
        Prochaine Maraude
      </Heading>
      {nextMaraude ? (
        <Box>
          <Text>Date: {new Date(nextMaraude.date).toLocaleDateString()}</Text>
          <Text>Heure: {nextMaraude.depart || "Heure non définie"}</Text>
          <Text>Lieu: {nextMaraude.itineraire || "Lieu non défini"}</Text>
          <Text>Inscrits: {nextMaraude.nbInscrits} / {nextMaraude.nbPersonnesAttendues}</Text>
        </Box>
      ) : (
        <Text>Aucune maraude à venir.</Text>
      )}
    </Box>
  );
};

export default NextMaraude;
