import React, { useContext } from 'react';
import { Box, Flex, Text, Icon, useColorModeValue, Button } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { FiHome, FiLogOut } from 'react-icons/fi';
import { BiCog } from 'react-icons/bi';
import { GiArchiveRegister } from 'react-icons/gi';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { MdAssignmentInd, MdBook } from 'react-icons/md';
import { FaRunning, FaUser } from 'react-icons/fa';
import { RiUserSettingsFill } from 'react-icons/ri';
import { AuthContext } from '../../../AuthContext';

const SidebarDesktop = ({ menuCollapse, handleToggleSidebar }) => {
  const bg = useColorModeValue('gray.100', 'gray.900');
  const color = useColorModeValue('gray.800', 'white');
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      height="100vh"
      width={menuCollapse ? '60px' : '200px'}
      backgroundColor={bg}
      color={color}
      boxShadow="lg"
      zIndex="1000"
      transition="width 0.3s"
      onClick={handleToggleSidebar}
    >
      <Flex direction="column" height="100%" justifyContent="space-between" p="4">
        <Box>
          <SidebarItem
            icon={FiHome}
            to="/dashboard"
            label="Accueil"
            menuCollapse={menuCollapse}
          />
          <SidebarItem
            icon={GiArchiveRegister}
            to="/maraude"
            label="Planning Maraude"
            menuCollapse={menuCollapse}
          />
          <SidebarItem
            icon={FaUser}
            to="/mon-espace"
            label="Mes Infos"
            menuCollapse={menuCollapse}
          />
          <SidebarItem
            icon={MdBook}
            to="/wiki"
            label="Wiki"
            menuCollapse={menuCollapse}
            />
            <SidebarItem
              icon={AiOutlineFileSearch}
              to="/fiches"
              label="Fiches Patients"
              menuCollapse={menuCollapse}
            />
            <SidebarItem
              icon={FaRunning}
              to="/maraude/ongoing"
              label="Maraude On Going"
              menuCollapse={menuCollapse}
            />
            <SidebarItem
              icon={RiUserSettingsFill}
              to="/gestion"
              label="Gestion"
              menuCollapse={menuCollapse}
            />
          </Box>
          <Button
            leftIcon={<FiLogOut />}
            onClick={handleLogout}
            width="100%"
            justifyContent={menuCollapse ? 'center' : 'flex-start'}
            variant="ghost"
            _hover={{ bg: 'gray.200' }}
          >
            {!menuCollapse && 'Déconnexion'}
          </Button>
        </Flex>
      </Box>
    );
  };

  const SidebarItem = ({ icon, to, label, menuCollapse }) => (
    <Link to={to}>
      <Flex align="center" p="3" borderRadius="md" _hover={{ bg: 'gray.200' }}>
        <Icon as={icon} w={6} h={6} />
        {!menuCollapse && (
          <Text ml="4" fontSize="md">
            {label}
          </Text>
        )}
      </Flex>
    </Link>
  );

  export default SidebarDesktop;
