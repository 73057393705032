import React from 'react';
import { Box, Button, Container, Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const SignupConfirmation = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/'); // Redirige vers la page de connexion, assurez-vous que la route "/login" existe
  };

  return (
    <Container maxW="container.sm">
      <Box
        bg="white"
        boxShadow="md"
        borderRadius="md"
        p={6}
        mt={6}
        textAlign="center"
      >
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          Inscription Validée
        </Text>
        <Text mb={4}>
          Merci pour votre inscription ! Votre compte sera validé dans les prochaines 24 heures. Veuillez vérifier votre boîte de réception et votre dossier spam pour plus d'informations.
        </Text>
        <Button colorScheme="blue" onClick={handleLoginRedirect}>
          Retour à la connexion
        </Button>
      </Box>
    </Container>
  );
};

export default SignupConfirmation;
