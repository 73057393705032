import React, { useState } from 'react';
import { Box, Flex, useBreakpointValue, IconButton } from '@chakra-ui/react';
import SidebarDesktop from './Sidebar/SidebarDesktop';
import SidebarMobile from './Sidebar/SidebarMobile';
import ColorModeSwitcher from './ColorModeSwitcher';

import './Layout.css';

const Layout = ({ children }) => {
  const [menuCollapse, setMenuCollapse] = useState(true);
  const handleToggleSidebar = () => {
    setMenuCollapse(!menuCollapse);
  };


  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Flex direction="column" height="100vh">
      {!isMobile && <SidebarDesktop menuCollapse={menuCollapse} handleToggleSidebar={handleToggleSidebar} />}
      {isMobile && <SidebarMobile />}
      <Box
        flex="1"
        ml={!isMobile && menuCollapse ? '60px' : '0'}
        mt={isMobile ? '60px' : '0'}
        p="4"
        overflowY="auto"
      >
      <ColorModeSwitcher position="fixed" top="1rem" right="1rem" />

        {children}
      </Box>
    </Flex>
  );
};

export default Layout;
