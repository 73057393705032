import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axiosInstance from '../../common/utils/axiosConfig';
import {
  Box,
  Button,
  Container,
  Text,
  VStack,
  useColorModeValue,
  Heading,
  Spinner,
  List,
  ListItem,
} from '@chakra-ui/react';
import Layout from '../../common/Layout';

const FichePatient = () => {
  const { id } = useParams();
  const [fichePatient, setFichePatient] = useState(null);
  const [interventions, setInterventions] = useState([]);
  const [loading, setLoading] = useState(true);

  const bgColor = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    const fetchFichePatient = async () => {
      try {
        const response = await axiosInstance.get(`/api/fichesPatients/${id}`);
        if (response.data && response.data.fichePatient) {
          setFichePatient(response.data);

          const interventionPromises = response.data.fichePatient.listInterventions.map(interventionId =>
            axiosInstance.get(`/api/fichesInterventions/${interventionId}`)
          );

          const interventionResponses = await Promise.all(interventionPromises);
          setInterventions(interventionResponses.map(res => res.data.fichesIntervention));
        }
        setLoading(false);
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
        setLoading(false);
      }
    };

    fetchFichePatient();
  }, [id]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner />
      </Box>
    );
  }

  if (!fichePatient || !fichePatient.fichePatient) {
    return <Text>Fiche patient non trouvée</Text>;
  }

  const {
    _id,
    name,
    surname,
    birthdate,
    description,
    antecedents,
    createdAt,
    updatedAt
  } = fichePatient.fichePatient;

  return (
    <Layout>
      <Container maxW="container.md" py={5}>
        <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={bgColor}>
          <Heading mb={4}>Fiche Patient</Heading>
          <VStack spacing={4} align="left">
            <DetailItem label="ID" value={_id} />
            <DetailItem label="Nom" value={name} />
            <DetailItem label="Prénom" value={surname} />
            <DetailItem label="Date de naissance" value={new Date(birthdate).toLocaleDateString()} />
            <DetailItem label="Description" value={description} />
            <DetailItem label="Antécédents" value={antecedents} />
            <DetailItem label="Date de création" value={new Date(createdAt).toLocaleDateString()} />
            <DetailItem label="Date de mise à jour" value={new Date(updatedAt).toLocaleDateString()} />
            <Link to={`/fiches/patients/edit/${id}`}>
              <Button colorScheme="teal">Modifier</Button>
            </Link>
          </VStack>
        </Box>

        <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={bgColor} mt={5}>
          <Heading mb={4}>Interventions</Heading>
          <List spacing={2}>
            {interventions.map(intervention => (
              <ListItem key={intervention._id}>
                <Link to={`/fiches/interventions/${intervention._id}`}>
                  <Button variant="link">{intervention.description}</Button>
                </Link>
              </ListItem>
            ))}
          </List>
        </Box>
      </Container>
    </Layout>
  );
};

const DetailItem = ({ label, value }) => (
  <Box>
    <Text as="span" fontWeight="bold">{label} :</Text> <Text as="span">{value}</Text>
  </Box>
);

export default FichePatient;
