import React, { useEffect, useState } from 'react';
import { Box, Button, VStack, Text, Table, Tbody, Tr, Td, Thead, Th } from '@chakra-ui/react';
import axiosInstance from '../common/utils/axiosConfig';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../common/Layout';

const FinishMaraude = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [maraude, setMaraude] = useState(null);

  useEffect(() => {
    const fetchMaraude = async () => {
      try {
        const response = await axiosInstance.get(`/api/maraude/maraude/${id}`);
        setMaraude(response.data);
      } catch (error) {
        console.error('Error fetching maraude:', error);
      }
    };

    fetchMaraude();
  }, [id]);

  const handleValidate = async () => {
    try {
      await axiosInstance.put(`/api/maraude/maraude/${id}/validate`);
      navigate('/maraude');
    } catch (error) {
      console.error('Error validating maraude:', error);
    }
  };

  if (!maraude) {
    return <Text>Loading...</Text>;
  }

  return (
    <Layout>
      <VStack spacing={4} p={4}>
        <Text>Maraude: {maraude.name}</Text>
        <Text>Lieu: {maraude.itineraire}</Text>
        <Text>Date: {new Date(maraude.date).toLocaleDateString()}</Text>
        <Text>Heure de départ: {maraude.depart}</Text>
        <Text>Heure d'appel au 115: {maraude.appel115}</Text>
        <Text>Chef: {maraude.responsable}</Text>
        <Text>Condition Météo: {maraude.conditionMeteo}</Text>
        <Text>Moral de l'Équipe: {maraude.moralEquipe}</Text>
        <Text>Ambiance de la Rue: {maraude.ambianceRue}</Text>
        <Text>Faits Notables: {maraude.faitsNotables}</Text>
        <Text>Heure de Fin: {maraude.retour}</Text>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Matériel</Th>
              <Th>Quantité</Th>
            </Tr>
          </Thead>
          <Tbody>
            {maraude.materiel.map((item) => (
              <Tr key={item._id}>
                <Td>{item.name}</Td>
                <Td>{item.nb}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Text>Soins: {maraude.soins}</Text>
        <Text>Suivis: {maraude.suivis}</Text>
        <Text>Rencontres: {maraude.rencontres}</Text>
        <Text>Orientation Dispensaire: {maraude.orientationDispensaire}</Text>
        <Text>Orientation PASS: {maraude.orientationPass}</Text>
        <Text>Orientation CCAS: {maraude.orientationCCAS}</Text>
        <Table variant="simple" mt={4}>
          <Thead>
            <Tr>
              <Th>Nom</Th>
              <Th>Prénom</Th>
              <Th>Email</Th>
              <Th>Grade</Th>
            </Tr>
          </Thead>
          <Tbody>
            {maraude.equipe.map((membre) => (
              <Tr key={membre._id}>
                <Td>{membre.name}</Td>
                <Td>{membre.surname}</Td>
                <Td>{membre.email}</Td>
                <Td>{membre.grade}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Button onClick={handleValidate}>Valider Maraude</Button>
      </VStack>
    </Layout>
  );
};

export default FinishMaraude;
