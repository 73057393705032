import React from 'react';
import { Box, Heading, Image, useColorModeValue } from '@chakra-ui/react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Nécessaire pour les styles de carousel
import '../assets/PhotoCarousel.css';

const PhotoCarousel = () => {
  const photos = [
    { id: 1, src: '../assets/carousel/001.jpg', alt: 'Photo 1' },
    { id: 2, src: '../assets/carousel/002.jpg', alt: 'Photo 2' },
    { id: 3, src: '../assets/carousel/003.jpg', alt: 'Photo 3' },
    { id: 4, src: '../assets/carousel/004.jpg', alt: 'Photo 4' },
    { id: 5, src: '../assets/carousel/005.jpg', alt: 'Photo 5' }
  ];

  return (
    <Box
      p={5}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      bg={useColorModeValue('white', 'gray.700')}
    >
      <Heading fontSize="xl" mb={4}>
        Dernières Photos
      </Heading>
      <Carousel showThumbs={false} autoPlay infiniteLoop>
        {photos.map((photo) => (
          <Box key={photo.id}>
            <Image src={photo.src} alt={photo.alt} boxSize="100%" objectFit="cover" />
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default PhotoCarousel;
