import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../common/utils/axiosConfig';
import {
  Box,
  Container,
  Text,
  Spinner,
  VStack,
  Heading,
  Button,
} from '@chakra-ui/react';
import { Line, Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import Layout from '../../common/Layout';

const MaraudeDataPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [aggregatedData, setAggregatedData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance.get('/api/fichesMaraudes/')
      .then(response => {
        const maraudes = response.data;
        setData(maraudes);
        setLoading(false);

        // Agréger les données
        const totals = maraudes.reduce((acc, maraude) => {
          acc.amplitude += maraude.amplitude || 0;
          acc.soins += maraude.soins || 0;
          acc.suivis += maraude.suivis || 0;
          acc.rencontres += maraude.rencontres || 0;
          acc.orientationDispensaire += maraude.orientationDispensaire || 0;
          acc.orientationPass += maraude.orientationPass || 0;
          acc.orientationCCAS += maraude.orientationCCAS || 0;
          return acc;
        }, {
          amplitude: 0,
          soins: 0,
          suivis: 0,
          rencontres: 0,
          orientationDispensaire: 0,
          orientationPass: 0,
          orientationCCAS: 0,
        });

        setAggregatedData(totals);

        // Préparer les données du graphique en ligne
        const lineChartData = {
          labels: maraudes.map(maraude => new Date(maraude.date).toLocaleDateString()),
          datasets: [
            {
              label: 'Moral des Rencontres',
              data: maraudes.map(maraude => maraude.moralRencontres === 'positif' ? 1 : (maraude.moralRencontres === 'neutre' ? 0 : -1)),
              borderColor: 'blue',
              fill: false,
            },
            {
              label: 'Moral de l\'Équipe',
              data: maraudes.map(maraude => maraude.moralEquipe === 'positif' ? 1 : (maraude.moralEquipe === 'neutre' ? 0 : -1)),
              borderColor: 'green',
              fill: false,
            },
            {
              label: 'Condition Météo',
              data: maraudes.map(maraude => maraude.conditionMeteo === 'ensoleillé' ? 1 : (maraude.conditionMeteo === 'neutre' ? 0 : -1)),
              borderColor: 'orange',
              fill: false,
            },
          ],
        };

        // Préparer les données du graphique en barres
        const barChartData = {
          labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets: [
            {
              label: 'Heures',
              data: [15.5, 0, 0, 0, 0, 0, 28.25, 0, 0, 17.75, 0, 0],
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
            {
              label: 'Coût Nominal (€)',
              data: [446.71, 0, 0, 0, 0, 0, 814.16, 0, 0, 255.77, 0, 0],
              backgroundColor: 'rgba(255, 159, 64, 0.2)',
              borderColor: 'rgba(255, 159, 64, 1)',
              borderWidth: 1,
            }
          ],
        };

        // Préparer les données du graphique en camembert
        const pieChartData = {
          labels: ['Soins', 'Suivis', 'Rencontres', 'Orientation Dispensaire', 'Orientation PASS', 'Orientation CCAS'],
          datasets: [
            {
              data: [5, 4, 9, 1, 0, 1],
              backgroundColor: ['red', 'blue', 'green', 'yellow', 'orange', 'purple'],
            },
          ],
        };

        setChartData({ lineChartData, barChartData, pieChartData });
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner />
      </Box>
    );
  }

  return (
    <Layout>
      <Box overflowY="auto" maxHeight="90vh">
        <Container maxW="container.xl" py={5}>
          <Heading mb={4}>Données des Maraudes</Heading>
          <Button onClick={() => navigate('/reporting/statistics')} colorScheme="blue">
  Voir les statistiques des maraudes
</Button>

          <VStack spacing={5} align="stretch">
            {aggregatedData && (
              <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg">
                <Heading size="md">Totaux en 2024</Heading>
                <Text><strong>Amplitude:</strong> {aggregatedData.amplitude} heures</Text>
                <Text><strong>Soins:</strong> {aggregatedData.soins}</Text>
                <Text><strong>Suivis:</strong> {aggregatedData.suivis}</Text>
                <Text><strong>Rencontres:</strong> {aggregatedData.rencontres}</Text>
                <Text><strong>Orientation Dispensaire:</strong> {aggregatedData.orientationDispensaire}</Text>
                <Text><strong>Orientation PASS:</strong> {aggregatedData.orientationPass}</Text>
                <Text><strong>Orientation CCAS:</strong> {aggregatedData.orientationCCAS}</Text>
              </Box>
            )}

            {chartData && (
              <>
                <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg">
                  <Heading size="md" mb={4}>Évolution des Moraux et Conditions Météo</Heading>
                  <Line data={chartData.lineChartData} />
                </Box>

                <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg">
                  <Heading size="md" mb={4}>Totaux des Heures et Coûts Nominal</Heading>
                  <Bar data={chartData.barChartData} />
                </Box>

                <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg">
                  <Heading size="md" mb={4}>Répartition des Totaux</Heading>
                  <Pie data={chartData.pieChartData} />
                </Box>
              </>
            )}

            <Button onClick={() => navigate('/reporting/generate', { state: { chartData } })} colorScheme="blue">
              Générer le rapport
            </Button>
          </VStack>
        </Container>
      </Box>
    </Layout>
  );
};

export default MaraudeDataPage;
