// ReviewMaraude.js
import React, { useEffect, useState } from 'react';
import { Box, Button, VStack, Text } from '@chakra-ui/react';
import axiosInstance from '../common/utils/axiosConfig';
import { useParams, useNavigate } from 'react-router-dom';

const ReviewMaraude = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [maraude, setMaraude] = useState(null);

  useEffect(() => {
    const fetchMaraude = async () => {
      const response = await axiosInstance.get(`/api/maraude/maraude/${id}`);
      setMaraude(response.data);
    };

    fetchMaraude();
  }, [id]);

  const handleFinalize = async () => {
    await axiosInstance.put(`/api/maraude/maraude/${id}/finalize`);
    navigate('/maraude');
  };

  if (!maraude) {
    return <Text>Loading...</Text>;
  }

  return (
    <VStack spacing={4}>
      <Text>Maraude: {maraude.name}</Text>
      <Text>Lieu: {maraude.lieu}</Text>
      <Text>Date: {new Date(maraude.date).toLocaleDateString()}</Text>
      <Text>Heure de départ: {maraude.heureDepart}</Text>
      <Text>Heure d'appel au 115: {maraude.heureAppel115}</Text>
      <Text>Chef: {maraude.chef}</Text>
      <Text>Condition Météo: {maraude.conditionMeteo}</Text>
      <Text>Moral de l'Équipe: {maraude.moralEquipe}</Text>
      <Text>Ambiance de la Rue: {maraude.ambianceRue}</Text>
      <Text>Faits Notables: {maraude.faitsNotables}</Text>
      <Text>Heure de Fin: {maraude.heureFin}</Text>
      <Button onClick={handleFinalize}>Finaliser Maraude</Button>
    </VStack>
  );
};

export default ReviewMaraude;
