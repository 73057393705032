import React, { useState, useEffect } from 'react';
import { Box, Button, VStack, HStack, Text, Table, Tbody, Tr, Td } from '@chakra-ui/react';
import axiosInstance from '../common/utils/axiosConfig';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from '../common/Layout';
import formatDate from '../common/formatdate';

const OngoingMaraude = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [maraude, setMaraude] = useState(null);
  const [interventions, setInterventions] = useState([]);

  useEffect(() => {
    const fetchMaraude = async () => {
      try {
        const response = await axiosInstance.get(`/api/maraude/maraude/${id}`);
        setMaraude(response.data);
      } catch (error) {
        console.error("Error fetching maraude:", error);
      }
    };

    const fetchInterventions = async () => {
      try {
        const response = await axiosInstance.get(`/api/maraude/maraude/${id}/interventions`);
        setInterventions(response.data);
      } catch (error) {
        console.error("Error fetching interventions:", error);
      }
    };

    fetchMaraude();
    fetchInterventions();
  }, [id]);

  const handleAddIntervention = async (type) => {
    const updatedCounts = { [type]: (maraude[type] || 0) + 1 };
    const updatedMaraude = { ...maraude, ...updatedCounts };
    setMaraude(updatedMaraude);

    try {
      await axiosInstance.put(`/api/maraude/maraude/${id}/counter`, updatedCounts);
    } catch (error) {
      console.error("Error updating maraude counts:", error);
    }
  };

  const handleEndMaraude = async () => {
    try {
      await axiosInstance.put(`/api/maraude/maraude/${id}/end`, {
        soins: maraude.soins,
        suivis: maraude.suivis,
        rencontres: maraude.rencontres,
        orientationDispensaire: maraude.orientationDispensaire,
        orientationPass: maraude.orientationPass,
        orientationCCAS: maraude.orientationCCAS
      });
      navigate(`/maraude/debrief/${id}`);
    } catch (error) {
      console.error("Error ending maraude:", error);
    }
  };

  return (
    <Layout>
      <VStack spacing={4} align="stretch" p={4}>
        {maraude && (
          <>
            <Box>
              <Text>Date: {formatDate(maraude.date)}</Text>
              <Text>Lieu: {maraude.itineraire}</Text>
              <Text>Responsable: {maraude.responsable}</Text>
            </Box>
            <HStack spacing={4}>
              <Button onClick={() => handleAddIntervention('soins')}>Ajouter Soin</Button>
              <Button onClick={() => handleAddIntervention('suivis')}>Ajouter Suivi</Button>
              <Button onClick={() => handleAddIntervention('rencontres')}>Ajouter Rencontre</Button>
              <Button onClick={() => handleAddIntervention('orientationDispensaire')}>Ajouter Orientation Dispensaire</Button>
              <Button onClick={() => handleAddIntervention('orientationPass')}>Ajouter Orientation PASS</Button>
              <Button onClick={() => handleAddIntervention('orientationCCAS')}>Ajouter Orientation CCAS</Button>
            </HStack>
            <Table variant="simple">
              <Tbody>
                {interventions.map((intervention) => (
                  <Tr key={intervention._id}>
                    <Td>{intervention.type}</Td>
                    <Td>{intervention.description}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <Box mt={4}>
              <Text fontWeight="bold">Interventions Anonymes</Text>
              <Text>Soins: {maraude.soins || 0}</Text>
              <Text>Suivis: {maraude.suivis || 0}</Text>
              <Text>Rencontres: {maraude.rencontres || 0}</Text>
              <Text>Orientation Dispensaire: {maraude.orientationDispensaire || 0}</Text>
              <Text>Orientation PASS: {maraude.orientationPass || 0}</Text>
              <Text>Orientation CCAS: {maraude.orientationCCAS || 0}</Text>
            </Box>
            <Box mt={8} mb={20}>
              <Button onClick={handleEndMaraude}>Fin de Maraude</Button>
            </Box>
          </>
        )}
      </VStack>
    </Layout>
  );
};

export default OngoingMaraude;
