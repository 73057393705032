import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import Layout from '../../common/Layout';

const Livret = () => {
  return (
    <Layout>
      <Box p={4}>
        <Heading as="h1" size="xl" mb={4}>Livret d'Accueil 2024</Heading>
        <div style={{ position: 'relative', width: '100%', height: 0, paddingTop: '141.4286%', paddingBottom: 0, boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)', marginTop: '1.6em', marginBottom: '0.9em', overflow: 'hidden', borderRadius: '8px', willChange: 'transform' }}>
          <iframe
            loading="lazy"
            style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, border: 'none', padding: 0, margin: 0 }}
            src="https://www.canva.com/design/DAF8qzMPHzI/yFuw5hmo4yC52vYpnIFoGg/view?embed"
            allowFullScreen
            allow="fullscreen">
          </iframe>
        </div>
      </Box>
    </Layout>
  );
};

export default Livret;