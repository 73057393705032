import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../common/utils/axiosConfig';
import {
  Box,
  Button,
  Container,
  Text,
  VStack,
  Input,
  Heading,
  useColorModeValue,
  Textarea,
  Switch,
} from '@chakra-ui/react';
import Layout from '../../common/Layout';

const FicheAdherent = () => {
  const { id } = useParams();
  const [adherent, setAdherent] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    grade: '',
    phone: '',
    email: '',
    birthdate: '',
    city: '',
    job: '',
    contact: '',
    contactphone: '',
    picright: '',
    info: '',
    validated: false,
    discordid: '',
  });

  const bgColor = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    const fetchAdherent = async () => {
      try {
        const response = await axiosInstance.get(`/api/fichesAdherents/${id}`);
        if (response.data.ficheAdherent) {
          const data = response.data.ficheAdherent;
          setAdherent(data);
          setFormData({
            name: data.name,
            surname: data.surname,
            grade: data.grade,
            phone: data.phone,
            email: data.email,
            birthdate: data.birthdate,
            city: data.city,
            job: data.job,
            contact: data.contact,
            contactphone: data.contactphone,
            picright: data.picright,
            info: data.info,
            validated: data.validated,
            discordid: data.discordid,
          });
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };

    fetchAdherent();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleUpdate = async () => {
    try {
      const response = await axiosInstance.put(`/api/fichesAdherents/${id}`, formData);
      setAdherent(response.data.ficheAdherent);
      setEditMode(false);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la fiche adhérent:', error);
    }
  };

  if (!adherent) {
    return <Text>Chargement... Actualisez la page dans quelques secondes</Text>;
  }

  const {
    name,
    surname,
    grade,
    phone,
    email,
    birthdate,
    city,
    job,
    contact,
    contactphone,
    picright,
    info,
    validated,
    discordid,
  } = adherent;

  return (
    <Layout>
      <Container maxW="container.md" py={5}>
        <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={bgColor}>
          <Heading mb={4}>Fiche Adhérent</Heading>
          <VStack spacing={4} align="left">
            {editMode ? (
              <>
                <DetailItemEdit label="Nom" name="name" value={formData.name} onChange={handleInputChange} />
                <DetailItemEdit label="Prénom" name="surname" value={formData.surname} onChange={handleInputChange} />
                <DetailItemEdit label="Grade" name="grade" value={formData.grade} onChange={handleInputChange} />
                <DetailItemEdit label="Téléphone" name="phone" value={formData.phone} onChange={handleInputChange} />
                <DetailItemEdit label="Email" name="email" value={formData.email} onChange={handleInputChange} />
                <DetailItemEdit label="Date de naissance" name="birthdate" value={formData.birthdate} onChange={handleInputChange} />
                <DetailItemEdit label="Ville" name="city" value={formData.city} onChange={handleInputChange} />
                <DetailItemEdit label="Profession" name="job" value={formData.job} onChange={handleInputChange} />
                <DetailItemEdit label="Contact d'urgence" name="contact" value={formData.contact} onChange={handleInputChange} />
                <DetailItemEdit label="Téléphone du contact" name="contactphone" value={formData.contactphone} onChange={handleInputChange} />
                <DetailItemEdit label="Droit à l'image" name="picright" value={formData.picright} onChange={handleInputChange} />
                <DetailItemEdit label="Informations complémentaires" name="info" value={formData.info} onChange={handleInputChange} isTextArea />
                <DetailItemEdit label="Discord ID" name="discordid" value={formData.discordid} onChange={handleInputChange} />
                <Box>
                  <Text as="span" fontWeight="bold">Validé :</Text>
                  <Switch name="validated" isChecked={formData.validated} onChange={handleInputChange} mt={2} />
                </Box>
                <Button onClick={handleUpdate} colorScheme="blue">Mettre à jour</Button>
                <Button onClick={() => setEditMode(false)}>Annuler</Button>
              </>
            ) : (
              <>
                <DetailItem label="Nom" value={name} />
                <DetailItem label="Prénom" value={surname} />
                <DetailItem label="Grade" value={grade} />
                <DetailItem label="Téléphone" value={phone} />
                <DetailItem label="Email" value={email} />
                <DetailItem label="Date de naissance" value={birthdate} />
                <DetailItem label="Ville" value={city} />
                <DetailItem label="Profession" value={job} />
                <DetailItem label="Contact d'urgence" value={contact} />
                <DetailItem label="Téléphone du contact" value={contactphone} />
                <DetailItem label="Droit à l'image" value={picright ? 'Oui' : 'Non'} />
                <DetailItem label="Informations complémentaires" value={info} />
                <DetailItem label="Discord ID" value={discordid} />
                <DetailItem label="Validé" value={validated ? 'Oui' : 'Non'} />
                <Button onClick={() => setEditMode(true)} colorScheme="blue">Modifier</Button>
              </>
            )}
          </VStack>
        </Box>
      </Container>
    </Layout>
  );
};

const DetailItem = ({ label, value }) => (
  <Box>
    <Text as="span" fontWeight="bold">{label} :</Text> <Text as="span">{value}</Text>
  </Box>
);

const DetailItemEdit = ({ label, name, value, onChange, isTextArea }) => (
  <Box>
    <Text as="span" fontWeight="bold">{label} :</Text>
    {isTextArea ? (
      <Textarea name={name} value={value} onChange={onChange} mt={2} />
    ) : (
      <Input type="text" name={name} value={value} onChange={onChange} mt={2} />
    )}
  </Box>
);

export default FicheAdherent;
