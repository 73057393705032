import React, { useState } from 'react';
import axiosInstance from '../../common/utils/axiosConfig';
import { Box, Button, Container, FormControl, FormLabel, Input, Select, Switch, Text, VStack, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const Signup = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    surname: '',
    birthdate: '',
    phone: '',
    city: '',
    contact: '',
    contactphone: '',
    picright: false,
    job: '',
    grade: 'nouveau',
    photo: null,
  });
  const toast = useToast();
  const navigate = useNavigate(); // Utilisez useNavigate pour la navigation

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, photo: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataWithPhoto = new FormData();
      for (const key in formData) {
        formDataWithPhoto.append(key, formData[key]);
      }

      const response = await axiosInstance.post('/api/auth/signup', formDataWithPhoto, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });

      toast({
        title: response.data.message,
        status: 'success',
        duration: 6000,
        isClosable: true,
      });

      navigate('/signup-confirmation'); // Redirige vers la page de confirmation
    } catch (error) {
      console.error('Erreur lors de l\'inscription:', error);
      toast({
        title: 'Erreur lors de l\'inscription. Veuillez réessayer.',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="container.sm">
      <Box
        bg="white"
        boxShadow="md"
        borderRadius="md"
        p={6}
        mt={6}
        maxH="80vh"
        overflowY="auto"
      >
        <Text fontSize="2xl" fontWeight="bold" mb={4}>
          Inscription
        </Text>
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Mot de passe</FormLabel>
              <Input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Nom</FormLabel>
              <Input
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Prénom</FormLabel>
              <Input
                name="surname"
                value={formData.surname}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Date de naissance</FormLabel>
              <Input
                type="date"
                name="birthdate"
                value={formData.birthdate}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Téléphone</FormLabel>
              <Input
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Ville</FormLabel>
              <Input
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Contact d'urgence</FormLabel>
              <Input
                name="contact"
                value={formData.contact}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Téléphone du contact</FormLabel>
              <Input
                name="contactphone"
                value={formData.contactphone}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl display="flex" alignItems="center">
              <FormLabel mb="0">J'accorde le Droit à l'image</FormLabel>
              <Switch
                isChecked={formData.picright}
                onChange={() => setFormData({ ...formData, picright: !formData.picright })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Profession</FormLabel>
              <Input
                name="job"
                value={formData.job}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Grade</FormLabel>
              <Select
                name="grade"
                value={formData.grade}
                onChange={handleChange}
              >
                <option value="nouveau">Nouveau</option>
                <option value="SSO1">SSO 1</option>
                <option value="SSO2">SSO 2</option>
                <option value="SSO3">SSO 3</option>
                <option value="SSO4">SSO 4</option>
                <option value="SSO4+">SSO 4+</option>
                <option value="SSO+">SSO +</option>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Photo (non fonctionnel pour l'instant) </FormLabel>
              <Input
                type="file"
                name="photo"
                accept="image/*"
                //onChange={handleFileChange}
              />
            </FormControl>
            <Button type="submit" colorScheme="blue" width="full">
              S'inscrire
            </Button>
          </VStack>
        </form>
      </Box>
    </Container>
  );
};

export default Signup;
