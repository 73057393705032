import React from 'react';
import MaraudeReport from './MaraudeReport';
import Layout from '../../common/Layout';

function OTGPage() {
  return (
    <Layout>
      <MaraudeReport />
    </Layout>
  );
}

export default OTGPage;
