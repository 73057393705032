import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axiosInstance from '../../common/utils/axiosConfig';
import {
  Box,
  Button,
  Container,
  Text,
  VStack,
  useColorModeValue,
  Heading,
  Input
} from '@chakra-ui/react';
import Sidebar from '../../common/Sidebar/SidebarDesktop';

const FicheIntervention = () => {
  const { id } = useParams();
  const [intervention, setIntervention] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    age: '',
    birthdate: '',
    description: '',
    bilan: '',
    date: '',
    photo: '',
    implique: '',
    antecedents: '',
    solution: '',
    lieu: '',
    maraudeId: '',
  });

  useEffect(() => {
    const fetchIntervention = async () => {
      try {
        const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}fichesInterventions/${id}`);
        if (response.data) {
          setIntervention(response.data);
          setFormData({
            ...response.data.fichesIntervention,
            birthdate: response.data.fichesIntervention.birthdate.split('T')[0],
            date: response.data.fichesIntervention.date.split('T')[0],
          });
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    };

    fetchIntervention();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUpdate = async () => {
    try {
      const response = await axiosInstance.put(`${process.env.REACT_APP_API_URL}fichesInterventions/${id}`, formData);
      setIntervention(response.data);
      setEditMode(false);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la fiche d\'intervention:', error);
    }
  };

  const bgColor = useColorModeValue('white', 'gray.700');

  if (!intervention) {
    return <Text>Chargement...</Text>;
  }

  const {
    name,
    surname,
    age,
    birthdate,
    description,
    bilan,
    date,
    photo,
    implique,
    antecedents,
    solution,
    lieu,
    maraudeId,
  } = intervention.fichesIntervention;

  return (
    <Container maxW="container.md" py={5}>
      <Sidebar />
      <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={bgColor}>
        <Heading mb={4}>Fiche d'Intervention</Heading>
        <VStack spacing={4} align="left">
          {editMode ? (
            <>
              <DetailItemEdit label="Nom" name="name" value={formData.name} onChange={handleInputChange} />
              <DetailItemEdit label="Prénom" name="surname" value={formData.surname} onChange={handleInputChange} />
              <DetailItemEdit label="Âge" name="age" value={formData.age} onChange={handleInputChange} />
              <DetailItemEdit label="Date de naissance" name="birthdate" value={formData.birthdate} onChange={handleInputChange} />
              <DetailItemEdit label="Description" name="description" value={formData.description} onChange={handleInputChange} />
              <DetailItemEdit label="Bilan" name="bilan" value={formData.bilan} onChange={handleInputChange} />
              <DetailItemEdit label="Date" name="date" value={formData.date} onChange={handleInputChange} />
              <DetailItemEdit label="Photo" name="photo" value={formData.photo} onChange={handleInputChange} />
              <DetailItemEdit label="Impliqué" name="implique" value={formData.implique} onChange={handleInputChange} />
              <DetailItemEdit label="Antécédents" name="antecedents" value={formData.antecedents} onChange={handleInputChange} />
              <DetailItemEdit label="Solution" name="solution" value={formData.solution} onChange={handleInputChange} />
              <DetailItemEdit label="Lieu" name="lieu" value={formData.lieu} onChange={handleInputChange} />
              <DetailItemEdit label="Maraude ID" name="maraudeId" value={formData.maraudeId} onChange={handleInputChange} />
              <Button onClick={handleUpdate} colorScheme="blue">Mettre à jour</Button>
              <Button onClick={() => setEditMode(false)}>Annuler</Button>
            </>
          ) : (
            <>
              <DetailItem label="Nom" value={name} />
              <DetailItem label="Prénom" value={surname} />
              <DetailItem label="Âge" value={age} />
              <DetailItem label="Date de naissance" value={new Date(birthdate).toLocaleDateString()} />
              <DetailItem label="Description" value={description} />
              <DetailItem label="Bilan" value={bilan} />
              <DetailItem label="Date" value={new Date(date).toLocaleDateString()} />
              <DetailItem label="Photo" value={photo} />
              <DetailItem label="Impliqué" value={implique} />
              <DetailItem label="Antécédents" value={antecedents} />
              <DetailItem label="Solution" value={solution} />
              <DetailItem label="Lieu" value={lieu} />
              <DetailItem label="Maraude ID" value={
                <Link to={`/fiches/maraudes/${maraudeId}`}>
                  {maraudeId}
                </Link>
              } />
              <Button onClick={() => setEditMode(true)} colorScheme="blue">Modifier</Button>
            </>
          )}
        </VStack>
      </Box>
    </Container>
  );
};

const DetailItem = ({ label, value }) => (
  <Box>
    <Text as="span" fontWeight="bold">{label} :</Text> <Text as="span">{value}</Text>
  </Box>
);

const DetailItemEdit = ({ label, name, value, onChange }) => (
  <Box>
    <Text as="span" fontWeight="bold">{label} :</Text>
    <Input type="text" name={name} value={value} onChange={onChange} mt={2} />
  </Box>
);

export default FicheIntervention;
