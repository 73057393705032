import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  Text,
  VStack,
  Heading,
  Input,
  Textarea,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Image,
} from '@chakra-ui/react';
import { Line, Bar, Pie } from 'react-chartjs-2';
import 'chart.js/auto';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import logo from '../../../assets/logo.png';

const ReportGenerationPage = () => {
  const location = useLocation();
  const { chartData } = location.state || {};

  const [introduction, setIntroduction] = useState('');
  const [comments, setComments] = useState({});
  const reportRef = useRef();

  const handleCommentChange = (e, chart) => {
    setComments({ ...comments, [chart]: e.target.value });
  };

  const generatePDF = () => {
    const doc = new jsPDF('p', 'mm', 'a4');
    doc.text('CSOR Report', 10, 10);
    doc.addImage(logo, 'PNG', 10, 15, 40, 20); // Adjust the size and position as needed

    doc.text('Renseignements juridiques', 10, 40);
    doc.text('Date création entreprise: 25-06-2020', 10, 50);
    doc.text('Forme juridique: Association déclarée', 10, 60);
    doc.text('Adresse postale: 3 RUE ANDRE MESSAGER 33520 BRUGES', 10, 70);
    doc.text('Numéro RNA: W332029562', 10, 80);
    doc.text('Numéro SIREN: 884763905', 10, 90);
    doc.text('Numéro SIRET (siège): 88476390500012', 10, 100);
    doc.text('Numéro TVA Intracommunautaire: FR23884763905', 10, 110);

    html2canvas(reportRef.current).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      doc.addImage(imgData, 'PNG', 10, 120, 190, 160);
      doc.save('report.pdf');
    });
  };

  if (!chartData) {
    return (
      <Container maxW="container.xl" py={5}>
        <Heading mb={4}>Les données du graphique ne sont pas disponibles.</Heading>
      </Container>
    );
  }

  return (
    <Container maxW="container.xl" py={5}>
      <Heading mb={4}>Génération de Rapport</Heading>
      <VStack spacing={5} align="stretch" ref={reportRef}>
        <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg">
          <Heading size="md" mb={4}>Introduction</Heading>
          <Textarea
            value={introduction}
            onChange={(e) => setIntroduction(e.target.value)}
            placeholder="Écrivez l'introduction du rapport ici..."
          />
        </Box>

        <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg">
          <Heading size="md" mb={4}>Évolution des Moraux et Conditions Météo</Heading>
          <Line data={chartData.lineChartData} />
          <Textarea
            mt={4}
            placeholder="Ajoutez un commentaire sur ce graphique..."
            value={comments.lineChart || ''}
            onChange={(e) => handleCommentChange(e, 'lineChart')}
          />
        </Box>

        <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg">
          <Heading size="md" mb={4}>Totaux des Heures et Coûts Nominal</Heading>
          <Bar data={chartData.barChartData} />
          <Textarea
            mt={4}
            placeholder="Ajoutez un commentaire sur ce graphique..."
            value={comments.barChart || ''}
            onChange={(e) => handleCommentChange(e, 'barChart')}
          />
        </Box>

        <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg">
          <Heading size="md" mb={4}>Répartition des Totaux</Heading>
          <Pie data={chartData.pieChartData} />
          <Textarea
            mt={4}
            placeholder="Ajoutez un commentaire sur ce graphique..."
            value={comments.pieChart || ''}
            onChange={(e) => handleCommentChange(e, 'pieChart')}
          />
        </Box>

        <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg">
          <Heading size="md" mb={4}>Tableau Final</Heading>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>Heures</Th>
                  <Th>Coût Nominal (€)</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>61.5</Td>
                  <Td>1516.65</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </VStack>

      <Button mt={5} colorScheme="blue" onClick={generatePDF}>Générer le PDF</Button>

      <Box mt={5}>
        <Text fontSize="sm">
          Renseignements juridiques | Date création entreprise: 25-06-2020 | Forme juridique: Association déclarée |
          Adresse postale: 3 RUE ANDRE MESSAGER 33520 BRUGES | Numéro RNA: W332029562 | Numéro SIREN: 884763905 |
          Numéro SIRET (siège): 88476390500012 | Numéro TVA Intracommunautaire: FR23884763905
        </Text>
      </Box>
    </Container>
  );
};

export default ReportGenerationPage;
