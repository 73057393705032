// src/components/Wiki/Articles/ArticleNewForm.js
import React, { useState } from 'react';
import { Box, FormControl, FormLabel, Input, Textarea, Button, VStack } from '@chakra-ui/react';
import axiosInstance from '../../common/utils/axiosConfig';

const ArticleNewForm = () => {
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [tags, setTags] = useState('');
  const [content, setContent] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const requestBody = {
      title,
      author,
      tags: tags.split(',').map(tag => tag.trim()),
      content,
    };

    try {
      const response = await axiosInstance.post('/api/wiki/articles', requestBody);
      console.log('Article créé avec succès:', response.data);
      setTitle('');
      setAuthor('');
      setTags('');
      setContent('');
    } catch (error) {
      console.error('Erreur lors de la création de l\'article:', error);
    }
  };

  return (
    <Box p={4} borderWidth={1} borderRadius="lg" shadow="md" bg="white" mt={4}>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Titre</FormLabel>
            <Input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Auteur</FormLabel>
            <Input
              type="text"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Tags (séparés par des virgules)</FormLabel>
            <Input
              type="text"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Contenu</FormLabel>
            <Textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </FormControl>
          <Button type="submit" colorScheme="blue" width="full">
            Créer
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default ArticleNewForm;
