import React from 'react';
import { Box, Grid, GridItem, Container } from '@chakra-ui/react';
import Layout from '../common/Layout';
import Counter from './Dashboard/Counter';
import NextMaraude from './Dashboard/NextMaraude';
import Announcements from './Dashboard/Announcements';
import PhotoCarousel from './Dashboard/PhotoCarousel';
import './assets/Dashboard.css';

const Dashboard = () => {
  return (
    <Layout>
      <Container maxW="container.lg">
        <Grid templateColumns="repeat(12, 1fr)" gap={6}>
          <GridItem colSpan={{ base: 12, md: 6 }}>
            <Counter />
          </GridItem>
          <GridItem colSpan={{ base: 12, md: 6 }}>
            <NextMaraude />
          </GridItem>
          <GridItem colSpan={12}>
            <Announcements />
          </GridItem>
          <GridItem colSpan={12}>
            <PhotoCarousel />
          </GridItem>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Dashboard;
