import React, { useState, useEffect } from 'react';
import axiosInstance from '../../common/utils/axiosConfig';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Heading,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../common/Layout';
import formatDate from '../../common/formatdate';

const CreateMaraudeForm = () => {
    const [itineraire, setItineraire] = useState('');
    const [date, setDate] = useState('');
    const [userId, setUserId] = useState('');
    const [nbPersonnesAttendues, setNbPersonnesAttendues] = useState(3); // Initialisé à 3 par défaut
    const [circuits, setCircuits] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchUser() {
            try {
                const token = localStorage.getItem('token');
                const userResponse = await axiosInstance.get('/api/users/me', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUserId(userResponse.data._id);
            } catch (error) {
                console.error('Erreur lors de la récupération de l\'utilisateur:', error);
            }
        }

        async function fetchCircuits() {
            try {
                const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/circuits/`);
                setCircuits(response.data); // Assurez-vous que la réponse est un tableau
            } catch (error) {
                console.error('Erreur lors de la récupération des circuits:', error);
                setCircuits([]); // Définir un tableau vide en cas d'erreur
            }
        }

        fetchUser();
        fetchCircuits();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const requestBody = {
            itineraire,
            date: formatDate(date),
            createur: userId,
            nbPersonnesAttendues
        };

        try {
            const response = await axiosInstance.post(
                `/api/maraude/maraude`,
                requestBody,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );

            console.log('Maraude créée avec succès:', response.data);
            navigate('/maraude/');
        } catch (error) {
            console.error('Erreur lors de la création de la maraude:', error);
        }
    };

    return (
        <Layout>
            <Box p={4}>
                <Heading as="h2" size="lg" mb={4}>Créer une nouvelle maraude</Heading>
                <form onSubmit={handleSubmit}>
                    <VStack spacing={4}>
                        <FormControl>
                            <FormLabel>Itinéraire</FormLabel>
                            <Select
                                value={itineraire}
                                onChange={(e) => setItineraire(e.target.value)}
                                placeholder="Sélectionnez un circuit"
                                required
                            >
                                {circuits.map((circuit) => (
                                    <option key={circuit._id} value={circuit.name}>
                                        {circuit.name}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Date</FormLabel>
                            <Input
                                type="date"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                                required
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Nombre de personnes souhaitées</FormLabel>
                            <Input
                                type="number"
                                value={nbPersonnesAttendues}
                                onChange={(e) => setNbPersonnesAttendues(parseInt(e.target.value))}
                                min={1}
                                required
                            />
                        </FormControl>
                        <Button type="submit" colorScheme="blue">Créer</Button>
                    </VStack>
                </form>
            </Box>
        </Layout>
    );
}

export default CreateMaraudeForm;
