// src/components/Wiki/SearchBar.js
import React from 'react';
import { Box, Input, Button, HStack, VStack } from '@chakra-ui/react';

const SearchBar = ({ searchTerm, setSearchTerm, themes, setFilterTag }) => {
  return (
    <VStack spacing={4} mb={4}>
      <Input
        type="text"
        placeholder="Rechercher par titre ou tag"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <HStack spacing={4}>
        {themes.map(theme => (
          <Button key={theme} onClick={() => setFilterTag(theme)}>
            {theme}
          </Button>
        ))}
      </HStack>
    </VStack>
  );
};

export default SearchBar;
