import React from 'react';
import { Box, Heading, Text, useColorModeValue } from '@chakra-ui/react';
import '../assets/Announcements.css';

const Announcements = () => {
  const announcements = [
    { id: 1, text: "C'est les vacances ! Pensez à Déclarer vos dispo de Maraudes" },
    { id: 2, text: "Nouvelle version du site 8/07/24. N'hésitez pas à remonter les bugs sur Discord" }
  ];

  return (
    <Box
      p={5}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      bg={useColorModeValue('white', 'gray.700')}
    >
      <Heading fontSize="xl" mb={4}>
        Annonces
      </Heading>
      <Box>
        {announcements.map((announcement) => (
          <Text key={announcement.id} mb={2}>{announcement.text}</Text>
        ))}
      </Box>
    </Box>
  );
};

export default Announcements;
