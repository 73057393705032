// src/components/Wiki/Articles/ArticleList.js
import React from 'react';
import { Box, SimpleGrid, Spinner } from '@chakra-ui/react';
import Article from './Article';

const ArticleList = ({ articles }) => {
  if (!articles.length) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box overflowY="auto" maxH="60vh">
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
        {articles.map((article) => (
          <Article key={article._id} article={article} />
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default ArticleList;
