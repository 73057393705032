// src/components/Fiches/Maraudes/FicheMaraude.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../common/utils/axiosConfig';
import {
  Box,
  Button,
  Container,
  Text,
  VStack,
  useColorModeValue,
  Heading,
  Spinner,
  List,
  ListItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import Layout from '../../common/Layout';

const FicheMaraude = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [maraude, setMaraude] = useState(null);
  const [interventions, setInterventions] = useState([]);
  const [loading, setLoading] = useState(true);

  const bgColor = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    const fetchMaraude = async () => {
      try {
        const response = await axiosInstance.get(`/api/fichesMaraudes/${id}`);
        if (response.data) {
          setMaraude(response.data);
          if (response.data.interventionsId && response.data.interventionsId.length > 0) {
            fetchInterventions(response.data.interventionsId);
          }
        } else {
          console.error('Maraude non trouvée');
          setMaraude(null);
        }
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération de la maraude:", error);
        setLoading(false);
      }
    };

    const fetchInterventions = async (interventionIds) => {
      try {
        const response = await axiosInstance.get(`/api/interventions`, {
          params: { ids: interventionIds.join(',') }
        });
        setInterventions(response.data.interventions);
      } catch (error) {
        console.error("Erreur lors de la récupération des interventions:", error);
      }
    };

    fetchMaraude();
  }, [id]);

  const handleEdit = () => {
    navigate(`/maraude/edit/${id}`);
  };

  const handleValidate = async () => {
    try {
      await axiosInstance.put(`/api/fichesMaraudes/${id}/validate`);
      await axiosInstance.post('/api/discord/send', { message: `La maraude ${maraude.name} est terminée.` });
      setMaraude({ ...maraude, finie: true });
    } catch (error) {
      console.error("Erreur lors de la validation de la maraude:", error);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner />
      </Box>
    );
  }

  if (!maraude) {
    return <Text>Maraude non trouvée</Text>;
  }

  return (
    <Layout>
      <Container maxW="container.md" py={5}>
        <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={bgColor}>
          <Heading mb={4}>{maraude.name || "Détails de la Maraude"}</Heading>
          <VStack spacing={4} align="left">
            <Text><strong>Date:</strong> {new Date(maraude.date).toLocaleDateString()}</Text>
            <Text><strong>Itinéraire:</strong> {maraude.itineraire}</Text>
            <Text><strong>Responsable:</strong> {maraude.responsable || "Non spécifié"}</Text>
            <Text><strong>Heure de départ:</strong> {maraude.depart || "Non spécifiée"}</Text>
            <Text><strong>Heure de retour:</strong> {maraude.retour || "Non spécifiée"}</Text>
            <Text><strong>Amplitude:</strong> {maraude.amplitude} heures</Text>
            <Text><strong>Appel 115:</strong> {maraude.appel115 || "Non spécifié"}</Text>
            <Text><strong>Soins:</strong> {maraude.soins}</Text>
            <Text><strong>Suivis:</strong> {maraude.suivis}</Text>
            <Text><strong>Rencontres:</strong> {maraude.rencontres}</Text>
            <Text><strong>Orientation Dispensaire:</strong> {maraude.orientationDispensaire}</Text>
            <Text><strong>Orientation PASS:</strong> {maraude.orientationPass}</Text>
            <Text><strong>Orientation CCAS:</strong> {maraude.orientationCCAS}</Text>
            <Text><strong>Faits Notables:</strong> {maraude.faitsNotables || "Non spécifiés"}</Text>
            <Text><strong>Matériel:</strong> {maraude.materiel.map(item => Object.values(item).join('')).join(", ")}</Text>
            <Text><strong>Moral des Rencontres:</strong> {maraude.moralRencontres}</Text>
            <Text><strong>Moral de l'Équipe:</strong> {maraude.moralEquipe}</Text>
            <Text><strong>Condition Météo:</strong> {maraude.conditionMeteo}</Text>

            <Box mt={2}>
              <Button variant="outline" onClick={handleEdit} mr={2}>
                Éditer
              </Button>
              <Button colorScheme="blue" onClick={handleValidate} disabled={maraude.finie}>
                Valider
              </Button>
            </Box>
          </VStack>
        </Box>

        <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={bgColor} mt={5}>
          <Heading mb={4}>Équipe</Heading>
          <List spacing={2}>
            {maraude.equipe.map(membre => (
              <ListItem key={membre._id}>
                <Text>{`${membre.name} ${membre.surname} (${membre.grade}) - ${membre.email}`}</Text>
              </ListItem>
            ))}
          </List>
        </Box>

        <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={bgColor} mt={5}>
          <Heading mb={4}>Présences</Heading>
          <List spacing={2}>
            {maraude.presence.map((person, index) => (
              <ListItem key={index}>
                <Text>{person}</Text>
              </ListItem>
            ))}
          </List>
        </Box>

        <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" bg={bgColor} mt={5}>
          <Heading mb={4}>Interventions</Heading>
          <TableContainer>
            <Table>
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Nom</Th>
                  <Th>Description</Th>
                  <Th>Date</Th>
                  <Th>Détail</Th>
                </Tr>
              </Thead>
              <Tbody>
                {interventions.map(intervention => (
                  <Tr key={intervention._id}>
                    <Td>{intervention._id}</Td>
                    <Td>{intervention.name}</Td>
                    <Td>{intervention.description}</Td>
                    <Td>{new Date(intervention.date).toLocaleDateString()}</Td>
                    <Td>
                      <Button colorScheme="teal" size="sm" onClick={() => navigate(`/fiches/intervention/${intervention._id}`)}>
                        Détail
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </Layout>
  );
};

export default FicheMaraude;
